import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import Utility, { getIsBill, getIsCheque, getIsExpenseBill, getIsFulfillment, getIsGoodsReceipt, getIsInvoice, getIsJournalEntry, getIsMakePayment, getIsOrder, getIsPackList, getIsPayslip, getIsPayrollCheck, getIsPickList, getIsQuotation, getIsReceivePayment, getIsShipList, getCapitalized, getIsSalesOrder, getIsEwayBill, getIsWorkOrder, getIsJobCard, getIsPurchaseRequisition, getIsQCDocument, getIsStockTransfer, getIsStockAdjustment, getIsSalesReturn, getIsJobWorkOutOrder, getIsBOMModule, getIsStockRequest, getIsStockIssue, getIsMachine, getIsServiceRequisition,getIsSecurityGateEntry, getIsQCInspection, getIsInspectionReport, getIsSOACustomer, getIsSOAVendor, getIsRequestForQuotation, getIsPurchaseInwardQuotation, getIsAgedReceivable } from "../Utilities/Utility";
import { TableColumnPopupType } from "../Constants/Constants";
import { getIsAccountModule } from "../Utilities/Utility";
import CustomFieldManager from "../Manager/CustomFieldManager";
import IndiaTaxParser from "./IndiaTaxParser";
import TemplateParser from "./DocumentModuleParser/TemplateParser";
import QuoteAndInvoiceParser from "./DocumentModuleParser/QuoteAndInvoiceParser";
import BillAndOrderParser from "./DocumentModuleParser/BillAndOrderParser";
import AccountParser from "./DocumentModuleParser/AccountParser";
import FulfillmentParser from "./DocumentModuleParser/FulfillmentParser";
import GoodsReceiptParser from "./DocumentModuleParser/GoodsReceiptParser";
import JournalEntryParser from "./DocumentModuleParser/JournalEntryParser";
import MakePaymentParser from "./DocumentModuleParser/MakePaymentParser";
import PackListParser from "./DocumentModuleParser/PackListParser";
import PickListParser from "./DocumentModuleParser/PickListParser";
import ReceivePaymentParser from "./DocumentModuleParser/ReceivePaymentParser";
import ShipListParser from "./DocumentModuleParser/ShipListParser";
import PayslipParser from "./DocumentModuleParser/PayslipParser";
import ChequeParser from "./DocumentModuleParser/ChequeParser";
import PayrollCheckParser from "./DocumentModuleParser/PayrollCheckParser";
import TableFooterUtility from "../Utilities/TableFooterUtility";
import AppManager from "../Manager/AppManager";
import EWayBillParser from "./DocumentModuleParser/EWayBillParser";
import WorkOrderParser from "./DocumentModuleParser/WorkOrderParser";
import JobCardParser from "./DocumentModuleParser/JobCardParser";
import PurchaseRequisitionParser from "./DocumentModuleParser/PurchaseRequisitionParser";
import QCDocumentParser from "./DocumentModuleParser/QCDocumentParser";
import StockTransferParser from "./DocumentModuleParser/StockTransferParser";
import StockAdjustmentParser from "./DocumentModuleParser/StockAdjustmentParser";
import SalesReturnParser from "./DocumentModuleParser/SalesReturnParser";
import AddressParser from "./AddressParser";
import JobWorkOutOrderParser from "./DocumentModuleParser/JobWorkOutOrderParser";
import BOMParser from "./DocumentModuleParser/BOMParser";
import StockRequestParser from "./DocumentModuleParser/StockRequestParser";
import StockIssueParser from "./DocumentModuleParser/StockIssueParser";
import MachineSchedulerHelper from "../Templates/MachineSchedulerTemplates/MachineSchedulerHelper";
import { parseGateEntryData } from "./DocumentModuleParser/SecurityEntryGateParser";
import ServiceRequisitionParser from "./DocumentModuleParser/ServiceRequisitionParser";
import QCInspectionParser from "./DocumentModuleParser/QCInspectionParser";
import SOACustomerParser from "./DocumentModuleParser/SOACustomerParser";
import SOAVendorParser from "./DocumentModuleParser/SOAVendorParser";
import InspectionReportParser from "./DocumentModuleParser/InspectionReportParser";
import RequestForQuotationParser from "./DocumentModuleParser/RequestForQuotationParser";
import PurchaseInwardQuotationParser from "./DocumentModuleParser/PurchaseInwardQuotationParser";
import AgedReceivableParser from "./DocumentModuleParser/AgedReceivableParser";

export default class NewDataParser {
    static getTemplateDataFromERP(dataFromAPI, isReadOnlyMode = false, isGeneratedData, payloadIndex = 0) {
        let templateDataToReturn = {}

        templateDataToReturn.contactInfo = dataFromAPI.contactInfo
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo

        dataFromAPI = this.getContactNumber(dataFromAPI)
        //type
        dataFromAPI.type = this.getApiType(dataFromAPI.type)
        let type = dataFromAPI.type.toLowerCase()
        templateDataToReturn.type = this.getTemplateType(dataFromAPI.type)
        templateDataToReturn.documentType = this.getTemplateDocumentType(templateDataToReturn.type)

        this.resetTemplateSetting(dataFromAPI)

        templateDataToReturn = TemplateParser.parseTemplate(dataFromAPI, templateDataToReturn)

        templateDataToReturn = NewDataParser.getDocumentData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode, payloadIndex)

        //update table column fields
        templateDataToReturn = NewDataParser.getTableColumnFields(dataFromAPI, templateDataToReturn, type)
        //update table column struc for IN
        templateDataToReturn = IndiaTaxParser.updateTableStructure(templateDataToReturn, undefined)

        templateDataToReturn.ownerEmail = dataFromAPI.ownerEmail
        //Client Email Ids
        templateDataToReturn.clientEmailIDs = dataFromAPI.contactInfo && dataFromAPI.contactInfo.emailId !== null ? [dataFromAPI.contactInfo.emailId] : []

        templateDataToReturn.taxBreakdown = dataFromAPI.taxBreakdown

        //get tax breakdown for IN
        var inTaxBreakdown = IndiaTaxParser.getIndiaTaxBreakdown(dataFromAPI)
        if (inTaxBreakdown.length > 0) {
            templateDataToReturn.taxBreakdown = inTaxBreakdown
        }

        // India Tax breakdown
        if (Utility.getIsIndiaCountry(templateDataToReturn)) {
            templateDataToReturn = IndiaTaxParser.getTaxBreakdown(dataFromAPI, templateDataToReturn)
        }

        //added TCS and TDS if those value exist
        templateDataToReturn.taxBreakdown = IndiaTaxParser.getTcsNTds(dataFromAPI, templateDataToReturn.taxBreakdown)

        // templateDataToReturn.taxBreakdown = this.getRoundingOff(dataFromAPI, templateDataToReturn.taxBreakdown)
        templateDataToReturn.roundingOff = 0
        if (dataFromAPI.roundingOff && dataFromAPI.roundingOff !== null) {
            templateDataToReturn.roundingOff = dataFromAPI.roundingOff
        }
        //Missing Informations
        templateDataToReturn.billToName = dataFromAPI.billToName
        templateDataToReturn.shipToName = dataFromAPI.shipToName
        templateDataToReturn.billTo = dataFromAPI.billTo
        templateDataToReturn.shipTo = dataFromAPI.shipTo
        templateDataToReturn.decimalScale = dataFromAPI.decimalScale
        templateDataToReturn.billToAddress = dataFromAPI.billToAddress
        templateDataToReturn.shipToAddress = dataFromAPI.shipToAddress
        templateDataToReturn.shipFromAddress = dataFromAPI.shipFromAddress
        templateDataToReturn.appCustomizations = dataFromAPI.appCustomizations
        templateDataToReturn.priceInclusiveOfTax = dataFromAPI.priceInclusiveOfTax
        templateDataToReturn.paymentLink = dataFromAPI.paymentLink
        templateDataToReturn.accountName = dataFromAPI.accountName
        templateDataToReturn.referenceDate = dataFromAPI.referenceDate
        templateDataToReturn.paymentType = dataFromAPI.paymentType
        templateDataToReturn.referenceNumber = dataFromAPI.referenceNumber
        templateDataToReturn.exchangeRate = dataFromAPI.exchangeRate
        dataFromAPI.remarks = dataFromAPI.notes ?? ''
        //customerOrderNumber
        if (dataFromAPI.customerOrderNumber) {
            templateDataToReturn.customerOrderNumber = dataFromAPI.customerOrderNumber
        }
        templateDataToReturn.tenantBarcodeSettings = dataFromAPI.tenantBarcodeSettings
        templateDataToReturn.isReadOnlyMode = false

        //total and due amount
        templateDataToReturn.total = dataFromAPI.total
        templateDataToReturn.due = dataFromAPI.due
        templateDataToReturn.subTotal = dataFromAPI.subTotal
        templateDataToReturn.discount = dataFromAPI.discount
        templateDataToReturn.taxAmount = dataFromAPI.taxAmount
        templateDataToReturn.amountBeforeTax = dataFromAPI.amountBeforeTax
        templateDataToReturn.cogs = dataFromAPI.cogsAmount

        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'amountInWords')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'subTotalInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'cgstInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'igstInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'sgstInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'roundingOffInWord')

        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'outStandingAmountInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'outstandingAmountIncludingCurrentInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'totalTaxAmountInWord')
        templateDataToReturn = this.getAmountInWords(dataFromAPI, templateDataToReturn, 'amountBeforeTaxInWord')
        templateDataToReturn = this.getAdditionalChargeAndGlobalDiscountInWords(dataFromAPI, templateDataToReturn)

        templateDataToReturn.isDraft = dataFromAPI.isDraft ?? false
        
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList === undefined) {
            this.updateMissingCustomFieldList()
        }
        else {
            this.updateDeletedCustomFieldList(templateDataToReturn.refNumber, templateDataToReturn.customFields, 'top')
        }

        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList !== undefined) {
            this.updateDeletedCustomFieldList(templateDataToReturn.refNumber, templateDataToReturn.customFields, 'bottom')
        }

        //update child custom field such as fulfillment or goods receipt
        this.updateChildCustomField(templateDataToReturn.refNumber, templateDataToReturn.childCustomFields)

        return templateDataToReturn
    }

    static getAmountInWordCase(words) {
        let newWordsString = words ?? undefined
        if(newWordsString !== undefined) {
            if (words.length > 0) {
                var oldWords = words.split(' ')
                var newWords = []
                if (words.length > 0) {
                    oldWords.forEach(element => {
                        newWords.push(getCapitalized(element))
                    });

                    newWordsString = newWords.join(' ')
                }
            }
        }
        return newWordsString
    }

    static getAmountInWords(dataFromAPI, templateDataToReturn, key) {
        templateDataToReturn[key] = this.getAmountInWordCase(dataFromAPI[key])
        return templateDataToReturn
    }

    static getAdditionalChargeAndGlobalDiscountInWords(dataFromAPI, templateDataToReturn, key) {

        if (dataFromAPI.additionalCharges && dataFromAPI.additionalCharges !==null) {
            let globalDiscounts = dataFromAPI.additionalCharges.globalDiscounts ?? []

            if(globalDiscounts.length === 0) {
                let globalDiscount = dataFromAPI.additionalCharges.globalDiscount ?? undefined

                if(globalDiscount !== undefined) {
                    templateDataToReturn.globalDiscountInWord = this.getAmountInWordCase(globalDiscount.amountInWord)
                }
            }
            else {
                let globalDiscountsString = []
                globalDiscounts.forEach(element => {
                    globalDiscountsString.push((element.name ?? '') + ' : ' + this.getAmountInWordCase(element.amountInWord))
                });

                if (globalDiscountsString.length > 0) {
                    templateDataToReturn.globalDiscountInWord = globalDiscountsString.join('<br/>')
                }
            }


            let additionalChargesDetails = dataFromAPI.additionalCharges.additionalChargesDetails ?? []

            if (additionalChargesDetails.length > 0) {
                let additionalChargesDetailsString = []
                additionalChargesDetails.forEach(element => {
                    additionalChargesDetailsString.push((element.additionalCharge ?? '') + ' : ' + this.getAmountInWordCase(element.chargeAmountInWord))
                });

                if (additionalChargesDetailsString.length > 0) {
                    templateDataToReturn.additionalChargeInWord = additionalChargesDetailsString.join('<br/>')
                }
            }
        }
        return templateDataToReturn
    }

    static getContactNumber(dataFromAPI) {
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo['contactNumber'] === undefined || dataFromAPI.tenantInfo['contactNumber']  === null) {
                dataFromAPI.tenantInfo['contactNumber'] = ''
            }
        }

        if (getIsFulfillment(dataFromAPI.type.toLowerCase())) {
            if (dataFromAPI.parentDocumentData.contactInformation === undefined || dataFromAPI.parentDocumentData.contactInformation === null) {
                dataFromAPI.parentDocumentData.contactInformation['contactNumber'] = ''
            }
        }
        else if (getIsGoodsReceipt(dataFromAPI.type.toLowerCase())) {
            if (dataFromAPI.parentDocumentData.contactInformation === undefined || dataFromAPI.parentDocumentData.contactInformation === null) {
                dataFromAPI.parentDocumentData.contactInformation['contactNumber'] = ''
            }
        }
        else if (dataFromAPI.contactInfo !== undefined && dataFromAPI.contactInfo !== null) {
            if (dataFromAPI.contactInfo['contactNumber'] === undefined || dataFromAPI.contactInfo['contactNumber'] === null) {
                dataFromAPI.contactInfo['contactNumber'] = ''
            }
        }
        // else {
        //     dataFromAPI.contactInfo = {}
        //     dataFromAPI.contactInfo['contactNumber'] = ''
        // }
        return dataFromAPI
    }

    static resetTemplateSetting(dataFromAPI) {
        if (getIsAccountModule(null, dataFromAPI.type.toLowerCase())) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsFulfillment(dataFromAPI.type) || getIsGoodsReceipt(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsExpenseBill(dataFromAPI.type, dataFromAPI.lineItems)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsBill(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsMakePayment(dataFromAPI.type) || getIsReceivePayment(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsJournalEntry(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsPickList(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsPackList(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsShipList(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsCheque(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsPayrollCheck(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsEwayBill(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsWorkOrder(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsJobWorkOutOrder(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsJobCard(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsPurchaseRequisition(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsQCDocument(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsQCInspection(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsInspectionReport(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsStockTransfer(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsStockAdjustment(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsSalesReturn(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsBOMModule(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsStockRequest(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsStockIssue(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsSecurityGateEntry(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if(getIsMachine(dataFromAPI.type)){
            TemplateSettingsManager.resetDefaultData(dataFromAPI,"")
        }
        else if(getIsSOACustomer(dataFromAPI.type)){
            TemplateSettingsManager.resetDefaultData(dataFromAPI,"")
        }
        else if (getIsSOAVendor(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, "")
        }
        else if (getIsAgedReceivable(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, "")
        }
    }

    static getDocumentData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode, payloadIndex) {
        var type = dataFromAPI.type.toLowerCase();
        AppManager.setDocumentType(type)
        if (getIsAccountModule(null, type)) {
            templateDataToReturn = AccountParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsOrder(type) || getIsBill(type)) {
            templateDataToReturn = BillAndOrderParser.parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode)
        }
        else if (getIsFulfillment(type)) {
            templateDataToReturn = FulfillmentParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsGoodsReceipt(type)) {
            templateDataToReturn = GoodsReceiptParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsJournalEntry(type)) {
            templateDataToReturn = JournalEntryParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsMakePayment(type)) {
            templateDataToReturn = MakePaymentParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsPackList(type)) {
            templateDataToReturn = PackListParser.parseData(dataFromAPI, templateDataToReturn, payloadIndex)
        }
        else if (getIsPickList(type)) {
            templateDataToReturn = PickListParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsQuotation(type)
            || getIsSalesOrder(type)
            || getIsInvoice(type)) {
            templateDataToReturn = QuoteAndInvoiceParser.parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode)
        }
        else if ( getIsReceivePayment(type)) {
            templateDataToReturn = ReceivePaymentParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsShipList(type)) {
            templateDataToReturn = ShipListParser.parseData(dataFromAPI, templateDataToReturn, payloadIndex)
        }
        else if (getIsPayslip(dataFromAPI.type)) {
            templateDataToReturn = PayslipParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsCheque(dataFromAPI.type)) {
            templateDataToReturn = ChequeParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsPayrollCheck(dataFromAPI.type)) {
            templateDataToReturn = PayrollCheckParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsEwayBill(dataFromAPI.type)) {
            templateDataToReturn = EWayBillParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsWorkOrder(dataFromAPI.type)) {
            templateDataToReturn = WorkOrderParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsJobWorkOutOrder(dataFromAPI.type)) {
            templateDataToReturn = JobWorkOutOrderParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsJobCard(dataFromAPI.type)) {
            templateDataToReturn = JobCardParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsPurchaseRequisition(dataFromAPI.type)) {
            templateDataToReturn = PurchaseRequisitionParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsQCDocument(dataFromAPI.type)) {
            templateDataToReturn = QCDocumentParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsQCInspection(dataFromAPI.type)) {
            templateDataToReturn = QCInspectionParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsInspectionReport(dataFromAPI.type)) {
            templateDataToReturn = InspectionReportParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsStockTransfer(dataFromAPI.type)) {
            templateDataToReturn = StockTransferParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsStockAdjustment(dataFromAPI.type)) {
            templateDataToReturn = StockAdjustmentParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsSalesReturn(dataFromAPI.type)) {
            templateDataToReturn = SalesReturnParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsBOMModule(dataFromAPI.type)) {
            templateDataToReturn = BOMParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsStockRequest(dataFromAPI.type)) {
            templateDataToReturn = StockRequestParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsStockIssue(dataFromAPI.type)) {
            templateDataToReturn = StockIssueParser.parseData(dataFromAPI, templateDataToReturn)
        } else if (getIsSecurityGateEntry(dataFromAPI.type)) {
            templateDataToReturn = parseGateEntryData(dataFromAPI, templateDataToReturn);
        }
        else if (getIsMachine(dataFromAPI.type)){
            templateDataToReturn = MachineSchedulerHelper.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsServiceRequisition(dataFromAPI.type)){
            templateDataToReturn = ServiceRequisitionParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsSOACustomer(type)) {
            templateDataToReturn = SOACustomerParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsSOAVendor(type)) {
            templateDataToReturn = SOAVendorParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsAgedReceivable(type)) {
            templateDataToReturn = AgedReceivableParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsRequestForQuotation(type)) {
            templateDataToReturn = RequestForQuotationParser.parseData(dataFromAPI, templateDataToReturn)
        }
        else if (getIsPurchaseInwardQuotation(type)) {
            templateDataToReturn = PurchaseInwardQuotationParser.parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode)
        }
        AddressParser.assignOriginalAddress(templateDataToReturn);

        if (getIsOrder(dataFromAPI.type) || getIsBill(dataFromAPI.type)) {
            AddressParser.swapAddress(templateDataToReturn, TemplateSettingsManager.getIsSwapAddress());
        }

        return templateDataToReturn
    }

    static getTableColumnFields(dataFromAPI, templateDataToReturn, type) {
        if (dataFromAPI.tableColumnPopup !== undefined) {
            templateDataToReturn.tableColumnPopup = dataFromAPI.tableColumnPopup;
        } else {
            if (dataFromAPI.appCustomizations && dataFromAPI.appCustomizations.customLabels && dataFromAPI.appCustomizations.customLabels.customLabelPair) {
                let columnData = dataFromAPI.appCustomizations.customLabels.customLabelPair;
                let currentLangCode = templateDataToReturn.languageInfo.code;
                if (currentLangCode === "id") {
                    currentLangCode = "bh";
                }
                columnData = columnData[currentLangCode] ? columnData[currentLangCode] : columnData['en'];
                if (columnData) {
                    let docType = "BUY";
                    if (type === "invoice" || type === "quote") {
                        docType = "SELL";
                    }

                    let json_columnData = {};
                    if (getIsAccountModule(null, templateDataToReturn.type)) {
                        json_columnData[TableColumnPopupType.accountName] = columnData["ACCOUNTS.TITLE." + docType];
                        json_columnData[TableColumnPopupType.description] = columnData["ACCOUNTS.DESCRIPTIONS." + docType];
                        json_columnData[TableColumnPopupType.amount] = columnData["ACCOUNTS.AMOUNT." + docType];
                        json_columnData[TableColumnPopupType.tax] = columnData["ACCOUNTS.TAX." + docType];
                        json_columnData[TableColumnPopupType.taxAmount] = columnData["ACCOUNTS.TAX_AMOUNT." + docType];
                        json_columnData[TableColumnPopupType.paymentAmount] = columnData["ACCOUNTS.PAYMENT_AMOUNT." + docType];
                        json_columnData[TableColumnPopupType.totalTaxAmount] = columnData["AUTOCOMPLETE.TAX." + docType];
                    }
                    else {
                        json_columnData[TableColumnPopupType.code] = columnData["PRODUCTS.CODE." + docType] ? columnData["PRODUCTS.CODE." + docType] : 'sn_number';
                        json_columnData[TableColumnPopupType.productName] = columnData["PRODUCTS.TITLE." + docType];
                        json_columnData[TableColumnPopupType.description] = columnData["PRODUCTS.DESCRIPTIONS." + docType];
                        json_columnData[TableColumnPopupType.uom] = columnData["PRODUCTS.UOM." + docType] ? columnData["PRODUCTS.UOM." + docType] : TableColumnPopupType.uom;
                        json_columnData[TableColumnPopupType.quantity] = columnData["PRODUCTS.QUANTITY." + docType];
                        json_columnData[TableColumnPopupType.unitPrice] = columnData["PRODUCTS.UNITPRICE." + docType];
                        json_columnData[TableColumnPopupType.productTax] = columnData["PRODUCTS.PRODUCT_TAX." + docType] ? columnData["PRODUCTS.PRODUCT_TAX." + docType] : "tax";
                        json_columnData[TableColumnPopupType.productDiscount] = columnData["PRODUCTS.PRODUCT_DISCOUNT." + docType] ? columnData["PRODUCTS.PRODUCT_DISCOUNT." + docType] : "discount";
                        json_columnData[TableColumnPopupType.discount] = columnData["PRODUCTS.DISCOUNT." + docType];
                        json_columnData[TableColumnPopupType.tax] = columnData["AUTOCOMPLETE.TAX." + docType];
                    }
                    if (TemplateSettingsManager.getRemoteTemplateUID() === null
                        || TemplateSettingsManager.getRemoteTemplateUID() === undefined ||
                        TemplateSettingsManager.getRemoteTemplateUID() === '') {
                        TemplateSettingsManager.updateTableInfo(json_columnData);
                    }

                }
            }

            if (getIsAccountModule(null, templateDataToReturn.type) || getIsExpenseBill(dataFromAPI.type, dataFromAPI.lineItems) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
                //remove existing non account option
                NewDataParser.removeTableColumnForAccount(templateDataToReturn);
                NewDataParser.updateAmountInWordsTableColumn(templateDataToReturn);
                NewDataParser.updateTotalAmountTableColumn(templateDataToReturn);
                NewDataParser.updateSubtotalTableColumn(templateDataToReturn);
                NewDataParser.updateRoundingOffTableColumn(templateDataToReturn, dataFromAPI);
                if (getIsExpenseBill(templateDataToReturn.type, templateDataToReturn.lineItems)) {
                    NewDataParser.updateMissingExpenseBillTableColumn(templateDataToReturn)
                    NewDataParser.updateOutstandingBalanceTableColumn(templateDataToReturn);
                    NewDataParser.updateOutstandingBalanceIncludesCurrentTableColumn(templateDataToReturn);
                    NewDataParser.reorderTableColumnForExpenseBill(templateDataToReturn)
                }
            }
            else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
                if (getIsFulfillment(templateDataToReturn.type)) {
                    NewDataParser.updateMissingFulfillmentTableColumn(templateDataToReturn);
                    NewDataParser.reorderTableColumnForFulfillment(templateDataToReturn)
                }
                else {
                    NewDataParser.removeTableColumnForGoodReceipt(templateDataToReturn);
                    NewDataParser.updateMissingGoodsReceiptTableColumn(templateDataToReturn)
                    NewDataParser.reorderTableColumnForGoodsReceipt(templateDataToReturn)
                }
                NewDataParser.updateMissingSignatureColumn();
            }
            else if (getIsJournalEntry(templateDataToReturn.type)) {
                NewDataParser.updateMissingContactColumn(templateDataToReturn)
            }
            else if (getIsPickList(templateDataToReturn.type)) {
                NewDataParser.updateMissingWarehouseColumn(templateDataToReturn)
                NewDataParser.updateMissingPickTableColumn(templateDataToReturn)
            }
            else if (getIsPackList(templateDataToReturn.type)) {
                NewDataParser.updateMissingPackTableColumn(templateDataToReturn)
            }
            else if (getIsShipList(templateDataToReturn.type)
                || getIsStockTransfer(templateDataToReturn.type)
                || getIsStockAdjustment(templateDataToReturn.type)
                || getIsQCDocument(templateDataToReturn.type)
                || getIsQCInspection(templateDataToReturn.type)
                || getIsInspectionReport(templateDataToReturn.type)
                || getIsBOMModule(templateDataToReturn.type)
                || getIsJobWorkOutOrder(templateDataToReturn.type)
                || getIsSecurityGateEntry(templateDataToReturn.type)
                || getIsRequestForQuotation(templateDataToReturn.type)
                || getIsSOACustomer(templateDataToReturn.type)
                || getIsSOAVendor(templateDataToReturn.type)
                || getIsAgedReceivable(templateDataToReturn.type)
                || getIsSalesReturn(templateDataToReturn.type)) {
            }
            else if (getIsWorkOrder(templateDataToReturn.type)) {
                NewDataParser.updateMissingWorkOrderColumn(templateDataToReturn)
            }
            else if (getIsStockIssue(templateDataToReturn.type)) {
                NewDataParser.updateMissingStockIssuesTableColumn(templateDataToReturn)
            }
            else if (getIsStockRequest(templateDataToReturn.type)) {
                NewDataParser.updateMissingStockRequestTableColumn(templateDataToReturn)
            }
            else {
                if (getIsBill(templateDataToReturn.type) || getIsOrder(templateDataToReturn.type)) {
                    var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
                    if(columnDataList !== undefined && columnDataList !== null) {
                        if(columnDataList.length > 0) {
                            if (columnDataList.map(x => x.type).includes(TableColumnPopupType.accountName) || !columnDataList.map(x => x.type).includes(TableColumnPopupType.productName)) {
                                TemplateSettingsManager.defaultTemplateSettings.tableInfo = TemplateSettingsManager.getTableColumnForProduct()
                            }
                        }
                    }
                }
                NewDataParser.updateMissingTableColumn(templateDataToReturn);
                NewDataParser.updateAmountInWordsTableColumn(templateDataToReturn);
                NewDataParser.updateTotalAmountTableColumn(templateDataToReturn);
                NewDataParser.updateSubtotalTableColumn(templateDataToReturn);
                NewDataParser.updateOutstandingBalanceTableColumn(templateDataToReturn);
                NewDataParser.updateOutstandingBalanceIncludesCurrentTableColumn(templateDataToReturn);
                NewDataParser.updateRoundingOffTableColumn(templateDataToReturn, dataFromAPI);
                NewDataParser.updateMissingExpectedDeliveryDateColumn(templateDataToReturn)
                if (getIsBill(templateDataToReturn.type) || getIsOrder(templateDataToReturn.type)) {
                    NewDataParser.updateMissingPreferVendorColumn(templateDataToReturn)
                }
                if (getIsSalesOrder(templateDataToReturn.type)) {
                    NewDataParser.updateMissingSalesOrderTableColumn(templateDataToReturn)
                }
                NewDataParser.reorderTableColumnForProduct(templateDataToReturn);
            }

            templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        }
        return templateDataToReturn
    }

    static updateMissingCustomFieldList() {
        var draggableComponentList = []
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList !== undefined) {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList.length > 0) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList.forEach(element => {
                    if (element.systemField !== null && element.systemField !== undefined) {
                        if (element.systemField !== '') {
                            draggableComponentList.push(element.systemField)
                        }
                    }
                });
            }
        }

        if (draggableComponentList.length === 0) {
            TemplateSettingsManager.updateCustomFieldList([])
        }
        else {
            var rowItemList = []
            CustomFieldManager.getCustomFields().forEach((element, index) => {
                var newRowItem = {}
                newRowItem.isSelected = draggableComponentList.includes(element.code)
                newRowItem.code = element.code
                newRowItem.index = index
                newRowItem.label = element.label
                newRowItem.value = element.value
                newRowItem.id = element.id
                rowItemList.push(newRowItem)
            });

            TemplateSettingsManager.updateCustomFieldList(rowItemList)
        }
    }

    static updateDeletedCustomFieldList(documentId, customFields, position) {
        var newList = []
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
        if(position === 'bottom') {
            customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList
        }

        var masterCustomFieldCodes = CustomFieldManager.getMaterCustomFieldCodes()

        if (masterCustomFieldCodes.length > 0) {
            //solve custom field is deleted from master
            customFieldList.forEach(element => {
                if (masterCustomFieldCodes.includes(element.code)) {
                    newList.push(element)
                }
            });
        }
        else {
            newList = customFieldList
        }
        //solve hidden custom field
        if (customFields !== undefined && customFields !== null) {
            if (customFields.length > 0) {
                var apiCustomFieldCodes = customFields.map(x => x.code)
                if (newList.length > 0) {
                    newList.forEach(element => {
                        if (apiCustomFieldCodes.length === 0) {
                            element.isSelected = false
                        }
                        else if (element.isSelected && !TemplateSettingsManager.getIsMultipleDocument()) {
                            element.isSelected = apiCustomFieldCodes.includes(element.code)
                        }
                    });
                }
            }
        }

        if(position === 'top') {
            TemplateSettingsManager.updateCustomFieldList(newList)
        }
        else {
            TemplateSettingsManager.updateBottomCustomFieldList(newList)
        }
        if(TemplateSettingsManager.getIsMultipleDocument()) {
            var existingCustomFieldList = TemplateSettingsManager.getCustomFieldListWithMultiDoc()
            if(position === 'bottom') {
                existingCustomFieldList = TemplateSettingsManager.getBottomCustomFieldListWithMultiDoc()
            }
            if(existingCustomFieldList !== undefined) {
                if (newList.length > 0) {
                    var customFieldListByDoc = []
                    newList.forEach(element => {
                        // element.isSelected = true
                        customFieldListByDoc.push(element)
                    });
                }
                var item = {
                    documentId: documentId,
                    customList: customFieldListByDoc,
                }
                existingCustomFieldList.push(item)
                if (position === 'top') {
                    TemplateSettingsManager.updateCustomFieldListWithMultiDoc(existingCustomFieldList)
                }
                else {
                    TemplateSettingsManager.updateBottomCustomFieldListWithMultiDoc(existingCustomFieldList)
                }
            }
        }
    }

    static updateChildCustomField(documentId, customFields) {
        var childCustomFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.childCustomFieldList ?? []
        var newList = []

        var masterCustomFieldCodes = CustomFieldManager.getMaterCustomFieldCodes()

        if (masterCustomFieldCodes.length > 0) {
            //solve custom field is deleted from master
            childCustomFieldList.forEach(element => {
                if (masterCustomFieldCodes.includes(element.code)) {
                    newList.push(element)
                }
            });
        }
        else {
            newList = childCustomFieldList
        }

        //solve hidden custom field
        if (customFields !== undefined && customFields !== null) {
            if (customFields.length > 0) {
                var apiCustomFieldCodes = customFields.map(x => x.code)
                if (newList.length > 0) {
                    newList.forEach(element => {
                        if (apiCustomFieldCodes.length === 0) {
                            element.isSelected = false
                        }
                        else if (element.isSelected) {
                            element.isSelected = apiCustomFieldCodes.includes(element.code)
                        }
                    });
                }
            }
        }

        TemplateSettingsManager.updateChildCustomFieldList(newList)

        if (TemplateSettingsManager.getIsMultipleDocument()) {
            var existingCustomFieldList = TemplateSettingsManager.getChildCustomFieldListWithMultiDoc()

            if (existingCustomFieldList !== undefined) {
                if (newList.length > 0) {
                    var customFieldListByDoc = []
                    newList.forEach(element => {
                        // element.isSelected = true
                        customFieldListByDoc.push(element)
                    });
                }
                var item = {
                    documentId: documentId,
                    customList: customFieldListByDoc,
                }
                existingCustomFieldList.push(item)
                TemplateSettingsManager.updateChildCustomFieldListWithMultiDoc(existingCustomFieldList)
            }
        }
    }

    static getRoundingOff(dataFromAPI, taxBreakdown) {
        var newTaxBreakdown = []

        if (taxBreakdown !== undefined && taxBreakdown !== null) {
            if (taxBreakdown.length > 0) {
                newTaxBreakdown = taxBreakdown
            }
        }
        if (dataFromAPI.roundingOff !== undefined && dataFromAPI.roundingOff !== null) {
            if (Math.abs(Number(dataFromAPI.roundingOff)) > 0) {
                newTaxBreakdown.push(
                    {
                        "taxId": "Rounding Off",
                        "taxName": "Rounding Off",
                        "taxAmount": Number(dataFromAPI.roundingOff)
                    }
                );
            }
        }
        return newTaxBreakdown
    }

    static updateMissingTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.lineNumber)) {
            var lineNumber = { "type": TableColumnPopupType.lineNumber, "isSelected": false, "name": "#" };
            columnDataList.splice(0, 0, lineNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.code)) {
            var code = { "type": TableColumnPopupType.code, "isSelected": false, "name": "sn_number" };
            columnDataList.splice(0, 0, code);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.image)) {
            var image = { "type": TableColumnPopupType.image, "isSelected": false, "name": "image" };
            columnDataList.splice(0, 0, image);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productNameWithDescription)) {
            var nameWithDescription = { "type": TableColumnPopupType.productNameWithDescription, "isSelected": false, "name": "name_with_description" };
            columnDataList.splice(2, 0, nameWithDescription);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.uom)) {
            var uom = { "type": TableColumnPopupType.uom, "isSelected": false, "name": "uom" };
            columnDataList.splice(3, 0, uom);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.quantityWithUom)) {
            var quantityWithUom = { "type": TableColumnPopupType.quantityWithUom, "isSelected": false, "name": "quantity_and_uom" };
            columnDataList.splice(4, 0, quantityWithUom);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.availableQuantity)) {
            var availableQuantity = { "type": TableColumnPopupType.availableQuantity, "isSelected": false, "name": "available_qty" };
            columnDataList.splice(4, 0, availableQuantity);
        }
        
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            var serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch" };
            columnDataList.splice(5, 0, serialBatch);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialNumber)) {
            var serialNumber = { "type": TableColumnPopupType.serialNumber, "isSelected": false, "name": "serial_number" };
            columnDataList.splice(5, 0, serialNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchNumber)) {
            var batchNumber = { "type": TableColumnPopupType.batchNumber, "isSelected": false, "name": "batch_number" };
            columnDataList.splice(5, 0, batchNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.manufacturingDate)) {
            var manufacturingDate = { "type": TableColumnPopupType.manufacturingDate, "isSelected": false, "name": "manufacturing_date" };
            columnDataList.splice(5, 0, manufacturingDate);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.expiryDate)) {
            var expiryDate = { "type": TableColumnPopupType.expiryDate, "isSelected": false, "name": "expiry_date" };
            columnDataList.splice(5, 0, expiryDate);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productTax)) {
            var tax = { "type": TableColumnPopupType.productTax, "isSelected": false, "name": "tax" };
            columnDataList.splice(6, 0, tax);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productTaxAmount)) {
            var taxAmount = { "type": TableColumnPopupType.productTaxAmount, "isSelected": false, "name": "tax_amount" };
            columnDataList.splice(6, 0, taxAmount);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productDiscount
        )) {
            var discount = { "type": TableColumnPopupType.productDiscount, "isSelected": false, "name": "discount" };
            columnDataList.splice(7, 0, discount);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productGlobalDiscount
        )) {
            var productGlobalDiscount = { "type": TableColumnPopupType.productGlobalDiscount, "isSelected": false, "name": "global_discount" };
            columnDataList.splice(7, 0, productGlobalDiscount);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productUnitPriceAfterDiscount
        )) {
            var productUnitPriceAfterDiscount = { "type": TableColumnPopupType.productUnitPriceAfterDiscount, "isSelected": false, "name": "unit_price_after_discount" };
            columnDataList.splice(7, 0, productUnitPriceAfterDiscount);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productDiscountPerUnit
        )) {
            var productDiscountPerUnit = { "type": TableColumnPopupType.productDiscountPerUnit, "isSelected": false, "name": "discount_per_unit" };
            columnDataList.splice(7, 0, productDiscountPerUnit);
        }


        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.amountBeforeTax
        )) {
            var amountBeforeTax = { "type": TableColumnPopupType.amountBeforeTax, "isSelected": false, "name": "amount_before_tax" };
            columnDataList.splice(8, 0, amountBeforeTax);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.beforeTax
        )) {
            var beforeTax = { "type": TableColumnPopupType.beforeTax, "isSelected": false, "name": "before_tax" };
            columnDataList.splice(12, 0, beforeTax);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.totalTaxAmount)) {
            var totalTaxAmount = { "type": TableColumnPopupType.totalTaxAmount, "isSelected": false, "name": "total_tax_amount" };
            columnDataList.splice(13, 0, totalTaxAmount);
        }

        if (Utility.getIsIndiaCountry(templateDataToReturn)) {
            if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.hsn
            )) {
                var hsn = { "type": TableColumnPopupType.hsn, "isSelected": false, "name": "hsn_or_sac" };
                columnDataList.splice(5, 0, hsn);
            }
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.globalDiscount
        )) {
            var globalDiscount = { "type": TableColumnPopupType.globalDiscount, "isSelected": true, "name": "global_discount" };
            columnDataList.splice(15, 0, globalDiscount);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.additionalCharge
        )) {
            var additionalCharge = { "type": TableColumnPopupType.additionalCharge, "isSelected": true, "name": "additional_charges" };
            columnDataList.splice(16, 0, additionalCharge);
        }
    }

    static updateMissingFulfillmentTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            var serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch" };
            columnDataList.splice(4, 0, serialBatch);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.warehouseName)) {
            var warehouseName = { "type": TableColumnPopupType.warehouseName, "isSelected": false, "name": "warehouse_name" };
            columnDataList.splice(8, 0, warehouseName);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialNumber)) {
            var serialNumber = { "type": TableColumnPopupType.serialNumber, "isSelected": false, "name": "serial_number" };
            columnDataList.splice(9, 0, serialNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchNumber)) {
            var batchNumber = { "type": TableColumnPopupType.batchNumber, "isSelected": false, "name": "batch_number" };
            columnDataList.splice(10, 0, batchNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.manufacturingDate)) {
            var manufacturingDate = { "type": TableColumnPopupType.manufacturingDate, "isSelected": false, "name": "manufacturing_date" };
            columnDataList.splice(11, 0, manufacturingDate);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.expiryDate)) {
            var expiryDate = { "type": TableColumnPopupType.expiryDate, "isSelected": false, "name": "expiry_date" };
            columnDataList.splice(12, 0, expiryDate);
        }

    }

    static updateMissingPickTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            let serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch", index: 9 };
            columnDataList.splice(9, 0, serialBatch);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialNumber)) {
            let serialNumber = { "type": TableColumnPopupType.serialNumber, "isSelected": false, "name": "serial_number", index: 10 };
            columnDataList.splice(10, 0, serialNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchNumber)) {
            let batchNumber = { "type": TableColumnPopupType.batchNumber, "isSelected": false, "name": "batch_number", index: 11 };
            columnDataList.splice(11, 0, batchNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchQuantity)) {
            let batchQuantity = { "type": TableColumnPopupType.batchQuantity, "isSelected": false, "name": "batch_quantity", index: 12 };
            columnDataList.splice(12, 0, batchQuantity);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.manufacturingDate)) {
            let manufacturingDate = { "type": TableColumnPopupType.manufacturingDate, "isSelected": false, "name": "manufacturing_date", index: 13 };
            columnDataList.splice(13, 0, manufacturingDate);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.expiryDate)) {
            let expiryDate = { "type": TableColumnPopupType.expiryDate, "isSelected": false, "name": "expiry_date", index: 14 };
            columnDataList.splice(14, 0, expiryDate);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.selectedRow)) {
            let selectedRow = { "type": TableColumnPopupType.selectedRow, "isSelected": false, "name": "row", index: 15 };
            columnDataList.splice(15, 0, selectedRow);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.selectedRack)) {
            let selectedRack = { "type": TableColumnPopupType.selectedRack, "isSelected": false, "name": "rack", index: 16 };
            columnDataList.splice(16, 0, selectedRack);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.selectedBin)) {
            let selectedBin = { "type": TableColumnPopupType.selectedBin, "isSelected": false, "name": "bin", index: 17 };
            columnDataList.splice(17, 0, selectedBin);
        }

    }

    static updateMissingPackTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            var serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch", index: 5 };
            columnDataList.splice(5, 0, serialBatch);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialNumber)) {
            var serialNumber = { "type": TableColumnPopupType.serialNumber, "isSelected": false, "name": "serial_number", index: 7 };
            columnDataList.splice(7, 0, serialNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchNumber)) {
            var batchNumber = { "type": TableColumnPopupType.batchNumber, "isSelected": false, "name": "batch_number", index: 8 };
            columnDataList.splice(8, 0, batchNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchQuantity)) {
            let batchQuantity = { "type": TableColumnPopupType.batchQuantity, "isSelected": false, "name": "batch_quantity", index: 9 };
            columnDataList.splice(9, 0, batchQuantity);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.manufacturingDate)) {
            var manufacturingDate = { "type": TableColumnPopupType.manufacturingDate, "isSelected": false, "name": "manufacturing_date", index: 10 };
            columnDataList.splice(10, 0, manufacturingDate);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.expiryDate)) {
            var expiryDate = { "type": TableColumnPopupType.expiryDate, "isSelected": false, "name": "expiry_date", index: 11 };
            columnDataList.splice(11, 0, expiryDate);
        }

    }

    static reorderTableColumnForFulfillment(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var newList = []
                columnDataList.forEach(element => {
                    if (element.type === TableColumnPopupType.serialBatch && element.index === undefined) {
                        element.index = 8
                    }
                    if (element.type === TableColumnPopupType.warehouseName && element.index === undefined) {
                        element.index = 7
                    }
                    newList.push(element)
                });
                TemplateSettingsManager.defaultTemplateSettings.tableInfo = newList
            }
        }
    }

    static updateMissingGoodsReceiptTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.warehouseName)) {
            var warehouseName = { "type": TableColumnPopupType.warehouseName, "isSelected": false, "name": "warehouse_name" };
            columnDataList.splice(8, 0, warehouseName);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            var serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch" };
            columnDataList.splice(8, 0, serialBatch);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialNumber)) {
            var serialNumber = { "type": TableColumnPopupType.serialNumber, "isSelected": false, "name": "serial_number" };
            columnDataList.splice(9, 0, serialNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.batchNumber)) {
            var batchNumber = { "type": TableColumnPopupType.batchNumber, "isSelected": false, "name": "batch_number" };
            columnDataList.splice(10, 0, batchNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.manufacturingDate)) {
            var manufacturingDate = { "type": TableColumnPopupType.manufacturingDate, "isSelected": false, "name": "manufacturing_date" };
            columnDataList.splice(11, 0, manufacturingDate);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.expiryDate)) {
            var expiryDate = { "type": TableColumnPopupType.expiryDate, "isSelected": false, "name": "expiry_date" };
            columnDataList.splice(12, 0, expiryDate);
        }
    }


    static updateMissingSalesOrderTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.qtyConvertedToWo)) {
            var qtyConvertedToWo = { "type": TableColumnPopupType.qtyConvertedToWo, "isSelected": false, "name": "wo_qty" };
            columnDataList.splice(10, 0, qtyConvertedToWo);
        }
    }

    static updateMissingStockIssuesTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.uom)) {
            var uom = { "type": TableColumnPopupType.uom, "isSelected": false, "name": "uom", index: columnDataList.length };
            columnDataList.splice(12, 0, uom);
        }
    }

    static updateMissingStockRequestTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.uom)) {
            var uom = { "type": TableColumnPopupType.uom, "isSelected": false, "name": "uom", index: columnDataList.length };
            columnDataList.splice(columnDataList.length, 0, uom);
        }
    }

    static reorderTableColumnForGoodsReceipt(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var newList = []
                columnDataList.forEach(element => {
                    if (element.type === TableColumnPopupType.warehouseName && element.index === undefined) {
                        element.index = 8
                    }
                    newList.push(element)
                });
                TemplateSettingsManager.defaultTemplateSettings.tableInfo = newList
            }
        }
    }

    static updateAmountInWordsTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.amountInWords)) {
            var amountInWords = { "type": TableColumnPopupType.amountInWords, "isSelected": false, "name": "amount_in_words" };
            if (columnDataList.length > 0) {
                columnDataList.splice(columnDataList.length, 0, amountInWords);
            }
            else {
                columnDataList.splice(0, 0, amountInWords);
            }
        }
    }

    static updateTotalAmountTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.totalAmount)) {
            var total = { "type": TableColumnPopupType.totalAmount, "isSelected": true, "name": "total" };
            if (columnDataList.length > 0) {
                columnDataList.splice(columnDataList.length - 1, 0, total);
            }
            else {
                columnDataList.splice(0, 0, total);
            }
        }
    }

    static updateSubtotalTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.subTotalAmount)) {
            var subTotal = { "type": TableColumnPopupType.subTotalAmount, "isSelected": true, "name": "subtotal" };
            if (columnDataList.length > 0) {
                columnDataList.splice(columnDataList.length - TableFooterUtility.getFooterListSize(templateDataToReturn), 0, subTotal);
            }
            else {
                columnDataList.splice(0, 0, subTotal);
            }
        }
    }

    static updateRoundingOffTableColumn(templateDataToReturn, dataFromAPI) {
        if(dataFromAPI.roundingOff !== undefined && dataFromAPI.roundingOff !== null) {
            templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
            var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
            if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.roundingOff)) {
                var roundingOff = { "type": TableColumnPopupType.roundingOff, "isSelected": true, "name": "rounding_off" };
                if (columnDataList.length > 0) {
                    columnDataList.splice(columnDataList.length - TableFooterUtility.getFooterListSize(templateDataToReturn), 0, roundingOff);
                }
                else {
                    columnDataList.splice(0, 0, roundingOff);
                }
            }
        }
    }

    static updateOutstandingBalanceTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.outstandingBalance)) {
            var outstandingBalance = { "type": TableColumnPopupType.outstandingBalance, "isSelected": false, "name": "outstanding_balance" };
            if (columnDataList.length > 0) {
                columnDataList.splice(columnDataList.length - 1, 0, outstandingBalance);
            }
            else {
                columnDataList.splice(0, 0, outstandingBalance);
            }
        }
    }

    static updateOutstandingBalanceIncludesCurrentTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.outstandingBalanceIncludesCurrent)) {
            var outstandingBalanceIncludesCurrent = { "type": TableColumnPopupType.outstandingBalanceIncludesCurrent, "isSelected": false, "name": "outstanding_balance_includes_current" };
            if (columnDataList.length > 0) {
                columnDataList.splice(columnDataList.length - 1, 0, outstandingBalanceIncludesCurrent);
            }
            else {
                columnDataList.splice(0, 0, outstandingBalanceIncludesCurrent);
            }
        }
    }

    static updateMissingSignatureColumn() {
        var signatureColumn = TemplateSettingsManager.defaultTemplateSettings.signatureInfo;

        if (signatureColumn === undefined) {
            TemplateSettingsManager.setSignatureInfo(TemplateSettingsManager.getDefaultSignatureInfo())
        }
    }

    static updateMissingContactColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var contactCode = undefined
        var contactName = undefined
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.contactCode)) {
            contactCode = { "type": TableColumnPopupType.contactCode, "isSelected": false, "name": "contact_code" };
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.contactCode)) {
            contactName = { "type": TableColumnPopupType.contactName, "isSelected": false, "name": "contact_name" };
        }

        if (columnDataList.length > 0 && contactCode !== undefined && contactName !== undefined) {
            columnDataList.splice(0, 0, contactName);
            columnDataList.splice(0, 0, contactCode);
        }
    }

    static updateMissingWarehouseColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var warehouseCode = undefined
        var warehouseName = undefined
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.warehouseCode)) {
            warehouseCode = { "type": TableColumnPopupType.warehouseCode, "isSelected": false, "name": "warehouse_code", index: 7 };
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.warehouseName)) {
            warehouseName = { "type": TableColumnPopupType.warehouseName, "isSelected": false, "name": "warehouse_name", index: 8 };
        }

        if (columnDataList.length > 0 && warehouseCode !== undefined && warehouseName !== undefined) {
            columnDataList.splice(columnDataList.length, 0, warehouseCode);
            columnDataList.splice(columnDataList.length, 0, warehouseName);
        }
    }

    static updateMissingWorkOrderColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var description = undefined
        var quantity = undefined
        var serialBatch = undefined
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.description)) {
            description = { "type": TableColumnPopupType.description, "isSelected": false, "name": "description", index: 4 };
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.quantity)) {
            quantity = { "type": TableColumnPopupType.quantity, "isSelected": false, "name": "quantity", index: 5 };
        }

        if (columnDataList.length > 0 && description !== undefined && quantity !== undefined) {
            columnDataList.splice(columnDataList.length, 0, description);
            columnDataList.splice(columnDataList.length, 0, quantity);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch", index: 6 };
        }

        if (columnDataList.length > 0 && serialBatch !== undefined) {
            columnDataList.splice(columnDataList.length, 0, serialBatch);
        }
    }


    static reorderTableColumnForProduct(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        let productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        var productCustomFieldType = columnDataList.filter(x => x.type === TableColumnPopupType.productCustomField)
        var productGlobalDiscountType = columnDataList.filter(x => x.type === TableColumnPopupType.productGlobalDiscount)
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var orderList = [
                    { type: TableColumnPopupType.lineNumber },
                    { type: TableColumnPopupType.code },
                    { type: TableColumnPopupType.image },
                    { type: TableColumnPopupType.productName },
                    { type: TableColumnPopupType.description },
                    { type: TableColumnPopupType.productNameWithDescription },
                    { type: TableColumnPopupType.hsn },
                    { type: TableColumnPopupType.quantity },
                    { type: TableColumnPopupType.uom },
                    { type: TableColumnPopupType.quantityWithUom },
                    { type: TableColumnPopupType.qtyConvertedToWo },
                    { type: TableColumnPopupType.serialBatch },
                    { type: TableColumnPopupType.serialNumber },
                    { type: TableColumnPopupType.batchNumber },
                    { type: TableColumnPopupType.manufacturingDate },
                    { type: TableColumnPopupType.expiryDate },
                    { type: TableColumnPopupType.unitPrice },
                    { type: TableColumnPopupType.productDiscount },
                    { type: TableColumnPopupType.productGlobalDiscount },
                    { type: TableColumnPopupType.productUnitPriceAfterDiscount },
                    { type: TableColumnPopupType.productDiscountPerUnit },
                    { type: TableColumnPopupType.productTax },
                    { type: TableColumnPopupType.productTaxAmount },
                    { type: TableColumnPopupType.expectedDeliveryDate },
                    { type: TableColumnPopupType.amountBeforeTax },
                    { type: TableColumnPopupType.amount },
                    { type: TableColumnPopupType.subTotalAmount },
                    { type: TableColumnPopupType.globalDiscount },
                    { type: TableColumnPopupType.additionalCharge },
                    { type: TableColumnPopupType.discount },
                    { type: TableColumnPopupType.beforeTax },
                    { type: TableColumnPopupType.tax },
                    { type: TableColumnPopupType.totalTaxAmount },
                    { type: TableColumnPopupType.totalAmount },
                    { type: TableColumnPopupType.cogs },
                    { type: TableColumnPopupType.outstandingBalance },
                    { type: TableColumnPopupType.outstandingBalanceIncludesCurrent },
                    { type: TableColumnPopupType.roundingOff },
                    { type: TableColumnPopupType.amountInWords },
                    { type: TableColumnPopupType.availableQuantity },
                ]

                if (getIsOrder(templateDataToReturn.type) || getIsBill(templateDataToReturn.type)) {
                    orderList.push({ type: TableColumnPopupType.supplierPartName });
                    orderList.push({ type: TableColumnPopupType.supplierPartNumber });
                    orderList.push({ type: TableColumnPopupType.supplierPartDescription });
                }

                if (productCustomFieldType.length > 0) {
                    productCustomFieldType.forEach((element) => {
                        orderList.push({ type: element.type, code: element.code})
                    });
                }

                if (productTableConfiguration !== undefined) {
                    if (productTableConfiguration.isCombinesLineItemGlobalDiscountField !== undefined && productTableConfiguration.isCombinesLineItemGlobalDiscountField !== null ) {
                        if (!productTableConfiguration.isCombinesLineItemGlobalDiscountField) {
                            orderList = orderList.filter(x => x.type !== TableColumnPopupType.productGlobalDiscount)
                            if (productGlobalDiscountType.length > 0) {
                                productGlobalDiscountType.forEach((element) => {
                                    orderList.push({accountCode: element.accountCode,  type: element.type, globalDiscountAccountCode: element.globalDiscountAccountCode })
                                });
                            }
                        }
                    }
                }

                var newList = []
                orderList.forEach(element => {
                    var items = columnDataList.filter(x => x.type === element.type)
                    if(element.code !== undefined) {
                        items = columnDataList.filter(x => x.type === element.type && x.code === element.code)
                    }
                    if (element.globalDiscountAccountCode !== undefined) {
                        items = columnDataList.filter(x => x.type === element.type && x.globalDiscountAccountCode === element.globalDiscountAccountCode)
                    }
                    if (items.length > 0) {
                        let item = items[0]
                        if (item.type === TableColumnPopupType.hsn && item.index === undefined) {
                            item.index = 7
                        }
                        if (item.type === TableColumnPopupType.quantityWithUom && item.index === undefined) {
                            item.index = 8
                        }
                        if (item.type === TableColumnPopupType.qtyConvertedToWo && item.index === undefined) {
                            item.index = 9
                        }
                        if (item.type === TableColumnPopupType.serialBatch && item.index === undefined) {
                            item.index = 9
                        }
                        if (item.type === TableColumnPopupType.serialNumber && item.index === undefined) {
                            item.index = 9
                        }
                        if (item.type === TableColumnPopupType.batchNumber && item.index === undefined) {
                            item.index = 9
                        }
                        if (item.type === TableColumnPopupType.manufacturingDate && item.index === undefined) {
                            item.index = 9
                        }
                        if (item.type === TableColumnPopupType.expiryDate && item.index === undefined) {
                            item.index = 9
                        }
                        if (item.type === TableColumnPopupType.productNameWithDescription && item.index === undefined) {
                            item.index = 11
                        }
                        if (item.type === TableColumnPopupType.productGlobalDiscount && item.index === undefined) {
                            item.index = 15
                        }
                        if (item.type === TableColumnPopupType.productUnitPriceAfterDiscount && item.index === undefined) {
                            item.index = 15
                        }
                        if (item.type === TableColumnPopupType.productDiscountPerUnit && item.index === undefined) {
                            item.index = 15
                        }
                        if (item.type === TableColumnPopupType.productTaxAmount && item.index === undefined) {
                            item.index = 17
                        }
                        if (item.type === TableColumnPopupType.expectedDeliveryDate && item.index === undefined) {
                            item.index = 17
                        }
                        if (item.type === TableColumnPopupType.amountInWords && item.index === undefined) {
                            item.index = 15
                        }
                        if (item.type === TableColumnPopupType.availableQuantity && item.index === undefined) {
                            item.index = 16
                        }
                        if (item.type === TableColumnPopupType.image) {
                            item.index = 2
                        }
                        newList.push(item)
                    }
                });

                var nonIndexList = newList.filter(x => x.index === undefined)
                if (nonIndexList.length > 0) {
                    newList.forEach((element, index) => {
                        element.index = index
                    });
                }
                TemplateSettingsManager.defaultTemplateSettings.tableInfo = newList
                // columnDataList = newList
            }
        }
    }

    static updateMissingExpectedDeliveryDateColumn(templateDataToReturn) {
        if (getIsOrder(templateDataToReturn.type) || getIsBill(templateDataToReturn.type)) {
            templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
            var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
            if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.expectedDeliveryDate)) {
                var expectedDeliveryDate = { "type": TableColumnPopupType.expectedDeliveryDate, "isSelected": false, "name": "expected_delivery_date" };
                if (columnDataList.length > 0) {
                    columnDataList.splice(columnDataList.length, 0, expectedDeliveryDate);
                }
                else {
                    columnDataList.splice(0, 0, expectedDeliveryDate);
                }
            }
            // templateDataToReturn.tableColumnPopup = columnDataList
            // TemplateSettingsManager.updateTablePopupInfo(columnDataList)
        }
    }

    static updateMissingPreferVendorColumn(templateDataToReturn) {
        if (getIsOrder(templateDataToReturn.type) || getIsBill(templateDataToReturn.type)) {
            
            templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
            var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
            
            //supplierPartName
            if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.supplierPartName)) {
                var supplierPartName = { "type": TableColumnPopupType.supplierPartName, "isSelected": false, "name": "supplier_part_name" };
                if (columnDataList.length > 0) {
                    columnDataList.splice(columnDataList.length, 0, supplierPartName);
                }
                else {
                    columnDataList.splice(0, 0, supplierPartName);
                }
            }

            //supplierPartNumber
            if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.supplierPartNumber)) {
                var supplierPartNumber = { "type": TableColumnPopupType.supplierPartNumber, "isSelected": false, "name": "supplier_part_number" };
                if (columnDataList.length > 0) {
                    columnDataList.splice(columnDataList.length, 0, supplierPartNumber);
                }
                else {
                    columnDataList.splice(0, 0, supplierPartNumber);
                }
            }

            //supplierPartDescription
            if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.supplierPartDescription)) {
                var supplierPartDescription = { "type": TableColumnPopupType.supplierPartDescription, "isSelected": false, "name": "supplier_part_description" };
                if (columnDataList.length > 0) {
                    columnDataList.splice(columnDataList.length, 0, supplierPartDescription);
                }
                else {
                    columnDataList.splice(0, 0, supplierPartDescription);
                }
            }
        }
    }

    static removeTableColumnForAccount(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var newList = []
                columnDataList.forEach(element => {
                    if (element.type !== TableColumnPopupType.code &&
                        element.type !== TableColumnPopupType.uom &&
                        element.type !== TableColumnPopupType.productTax &&
                        element.type !== TableColumnPopupType.productDiscount &&
                        element.type !== TableColumnPopupType.amountBeforeTax) {
                        newList.push(element)
                    }
                });
                columnDataList = newList
            }
        }
    }

    static removeTableColumnForGoodReceipt(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var newList = []
                columnDataList.forEach(element => {
                    if (element.type !== TableColumnPopupType.serialBatch) {
                        newList.push(element)
                    }
                });
                columnDataList = newList
                TemplateSettingsManager.updateTablePopupInfo(newList)
            }
        }
    }

    static updateMissingExpenseBillTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.tax)) {
            var tax = { "type": TableColumnPopupType.tax, "isSelected": true, "name": "tax" };
            columnDataList.splice(8, 0, tax);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.taxAmount)) {
            var taxAmount = { "type": TableColumnPopupType.taxAmount, "isSelected": true, "name": "tax_amount" };
            columnDataList.splice(9, 0, taxAmount);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.totalTaxAmount)) {
            var totalTaxAmount = { "type": TableColumnPopupType.totalTaxAmount, "isSelected": true, "name": "total_tax_amount" };
            columnDataList.splice(9, 0, totalTaxAmount);
        }
    }

    static reorderTableColumnForExpenseBill(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var orderList = TemplateSettingsManager.getTableColumnOptionsForExpenseBill().map(x => x.type)

                var newList = []
                orderList.forEach((element, index) => {
                    var item = columnDataList.find(x => x.type === element)
                    if(item !== undefined) {
                        item.index = index
                        newList.push(item)
                    }
                });
                TemplateSettingsManager.defaultTemplateSettings.tableInfo = newList
            }
        }
    }


    static getApiType(type) {
        if (type !== undefined && type !== null) {
            switch (type.toLowerCase()) {
                case 'pick list':
                    return 'pick_list'
                case 'pack list':
                    return 'pack_list'
                case 'ship list':
                    return 'ship_list'
                case 'sales order':
                    return 'sales_order'
                case 'eway bill summary':
                    return 'e_way_bill_summary'
                case 'eway bill detail':
                    return 'e_way_bill_detail'
                default:
                    return type
            }
        }
        return ''
    }

    static getTemplateType(type) {
        if (type !== undefined && type !== null) {
            switch (type.toLowerCase()) {
                case 'quote':
                    return 'quotation'
                case 'debit name':
                    return 'debit_note'
                case 'credit note':
                    return 'credit_note'
                case 'receipt':
                    return 'receive_payment'
                case 'payment':
                    return 'make_payment'
                case 'pick list':
                    return 'pick_list'
                case 'pack list':
                    return 'pack_list'
                case 'ship list':
                    return 'ship_list'
                default:
                    return type.toLowerCase()
            }
        }
        return ''
    }


    static getDocumentTemplateType(type) {
        if (type === 'picklist') {
            return 'pick_list'
        }
        else if (type === 'packlist') {
            return 'pack_list'
        }
        else if (type === 'shiplist') {
            return 'ship_list'
        }

        return type
    }


    static getTemplateDocumentType(defaultValue) {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== undefined && TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== null) {
            var type = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type
            if (type.labelText !== undefined && type.labelText !== null) {
                return type.labelText
            }
        }
        if(getIsPayslip(defaultValue)) {
            defaultValue = 'earning_statement'
        }
        if (getIsOrder(defaultValue)) {
            defaultValue = 'purchase_order'
        }
        return defaultValue
    }

    static assignCustomFieldValue(data) {
        if (data.customFields === undefined || data.customFields === null) {
            return data
        }

        if (data.customFields.length === 0) {
            return data
        }

        data.componentList.forEach(component => {
            if (component.systemField !== undefined) {
                var customField = data.customFields.filter(x => x.code === component.systemField)
                if (customField.length > 0) {
                    component.text = customField[0].value
                    component.newText = customField[0].value
                }
            }
        });

        return data
    }

    static getDefaultValue(value) {
        if (value !== undefined && value !== null) {
            return value
        }
        return ''
    }

    static getDocumentDataFromTemplate(templateDataToReturn) {
        templateDataToReturn.documentType = this.getTemplateDocumentType(templateDataToReturn.type)
        templateDataToReturn = TemplateParser.parseTemplate(templateDataToReturn, templateDataToReturn)
        return templateDataToReturn
    }


}
