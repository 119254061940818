import { DKButton, DKIcons } from 'deskera-ui-library';
import { toString } from 'lodash';
import { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { BARCODE_FORMAT } from '../../Constants/Constant';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  FIELD_TYPE,
  LabelCell,
  LabelCellItem,
  getValue
} from './LabelTemplateHelper';
import QRCode from 'react-qr-code';
import { HTMLTag } from 'deskera-doc-builder-lib';

interface LabelCellViewProps {
  data: LabelCell;
  cellIndex: number;
  deleteCell?: Function;
  cellToEdit?: LabelCell;
  setCellToEdit?: Function;
  dragStart?: Function;
  dragEnter?: Function;
  drop?: Function;
  labelSettings: any;
  isDragging?: boolean;
  isReadOnly: boolean;
}

const LabelCellView: React.FC<LabelCellViewProps> = ({
  data,
  cellIndex,
  deleteCell,
  cellToEdit,
  setCellToEdit,
  dragStart,
  dragEnter,
  drop,
  labelSettings,
  isDragging,
  isReadOnly
}) => {
  const [isHover, setIsHover] = useState(false);
  const [cellData, setCellData] = useState<LabelCell>();

  useEffect(() => {
    if (
      cellToEdit &&
      data.rowIndex === cellToEdit.rowIndex &&
      data.colIndex === cellToEdit.colIndex
    ) {
      setCellData({ ...cellToEdit });
    } else {
      setCellData({ ...data });
    }
  }, [cellToEdit, data]);

  return cellData ? (
    <div
      className={'p-2' + (cellIndex !== 0 ? ' border-l' : '')}
      style={{
        width:
          cellData.width && !isNaN(Number(cellData.width))
            ? parseInt(cellData.width) + 'mm'
            : '100%',
        position: 'relative',
        backgroundColor:
          cellToEdit &&
          data.rowIndex === cellToEdit.rowIndex &&
          data.colIndex === cellToEdit.colIndex
            ? '#fbfbfb'
            : 'unset',
        cursor: isReadOnly
          ? 'unset'
          : cellToEdit || isReadOnly
          ? 'not-allowed'
          : 'move',
        borderColor: '#000b'
      }}
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}
      draggable={!cellToEdit && !isReadOnly}
      onDragStart={(e) => !isReadOnly && dragStart && dragStart(cellIndex)}
      onDragEnter={(e) => !isReadOnly && dragEnter && dragEnter(cellIndex)}
      onDragEnd={(e) => !isReadOnly && !cellToEdit && drop && drop(e)}
      onDragOver={(e) => (isDragging ? e.preventDefault() : null)}
    >
      {cellData.items?.map((item: any, itemIndex: number) => (
        <LabelCellItemView
          key={itemIndex}
          data={item}
          labelSettings={labelSettings}
        />
      ))}
      {isHover && !cellToEdit && setCellToEdit && !isReadOnly && (
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <DKButton
            icon={DKIcons.ic_edit}
            className=""
            onClick={() => setCellToEdit(deepClone(cellData))}
          />
          <DKButton
            icon={DKIcons.ic_delete}
            className=""
            onClick={() => deleteCell && deleteCell(cellIndex)}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

interface LabelCellItemViewProps {
  data: LabelCellItem;
  labelSettings: any;
}

const LabelCellItemView: React.FC<LabelCellItemViewProps> = ({
  data,
  labelSettings
}: LabelCellItemViewProps) => {
  let xDimension =
    (!isNaN(Number(labelSettings.xDimension))
      ? Number(labelSettings.xDimension)
      : 0.43) * 1.55;
  let quietZone =
    (!isNaN(Number(labelSettings.quietZone))
      ? Number(labelSettings.quietZone)
      : 6.3) * 1.55;
  let barcodeHeight =
    (!isNaN(Number(labelSettings.barcodeHeight))
      ? Number(labelSettings.barcodeHeight)
      : 13) * 1.55;

  const getStyleFromValue = (style: string | undefined) => {
    switch (style) {
      case 'Bold':
        return { fontWeight: 'bold' };
      case 'Italic':
        return { fontStyle: 'italic' };
      case 'Underline':
        return { textDecoration: 'underline' };
      default:
        return {};
    }
  };

  const alignmentMap: Record<string, string> = {
    Left: 'flex-start',
    Center: 'center',
    Right: 'flex-end'
  };

  return !!data ? (
    <div
      className="flex"
      style={{
        flexDirection:
          data.barcodePosition === 'Top' ? 'column-reverse' : 'column',
        position: data.fixedPosition ? 'absolute' : 'unset',
        top:
          data.topMargin && !isNaN(Number(data.topMargin))
            ? Number(data.topMargin)
            : 'unset',
        left:
          data.leftMargin && !isNaN(Number(data.leftMargin))
            ? Number(data.leftMargin)
            : 'unset',
        width:
          data.width && !isNaN(Number(data.width))
            ? Number(data.width)
            : 'unset'
      }}
    >
      <div
        className="flex"
        style={{
          flexDirection: data.direction === 'Horizontal' ? 'row' : 'column',
          alignItems: alignmentMap[data.styleAlignment || 'Left']
        }}
      >
        {data.label && (
          <div
            className="pr-2"
            style={{
              fontSize:
                (!isNaN(Number(data.labelFontSize)) &&
                  Number(data.labelFontSize)) ||
                12,
              ...getStyleFromValue(data.labelStyle)
            }}
          >
            <b>
              {data.label}
              {data.value && data.value !== 0 && <>: </>}
            </b>
          </div>
        )}
        <div
          style={{
            fontSize:
              (!isNaN(Number(data.valueFontSize)) &&
                Number(data.valueFontSize)) ||
              12,
            ...getStyleFromValue(data.valueStyle)
          }}
        >
          {data.type === FIELD_TYPE.IMAGE ? (
            <div className="image-preview">
              <img
                src={data.value}
                style={{
                  maxWidth: '100%',
                  maxHeight: '80px',
                  objectFit: 'contain',
                  borderRadius: '4px'
                }}
              />
            </div>
          ) : data.type === FIELD_TYPE.SELECT_BARCODE_QR_CODE ? (
            data.showQRCode ? (
              <>
                <QRCode
                  id={HTMLTag.QR_CODE_PREFIX + data.key}
                  value={data.value}
                  size={80}
                />
              </>
            ) : (
              <>
                <Barcode
                  value={toString(data.value)}
                  width={xDimension}
                  height={barcodeHeight}
                  format={
                    BARCODE_FORMAT[labelSettings.barcodeType || 'CODE_128']
                  }
                  textAlign="center"
                  textMargin={5}
                  displayValue={true}
                  fontSize={12}
                  marginLeft={quietZone}
                  marginRight={quietZone}
                />
              </>
            )
          ) : (
            getValue(data)
          )}
        </div>
      </div>
      {data.showBarcode && (data.value || data.value === 0) && (
        <Barcode
          value={toString(data.value)}
          width={xDimension}
          height={barcodeHeight}
          format={BARCODE_FORMAT[labelSettings.barcodeType || 'CODE_128']}
          displayValue={false}
          marginLeft={quietZone}
          marginRight={quietZone}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default LabelCellView;
