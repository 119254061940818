import { showLoader, removeLoader } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  GENERATE_CN_DN_LIST,
  JWO_DISPATCH_STATUS,
  JWO_RECEIPT_STATUS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../Constants/Constant';
import BillService from '../../Services/Bill';
import {
  getUpdatedBillObject,
  getUpdatedFABillObject
} from '../Bills/BillHelper';
import Utility, { deepClone } from '../../Utility/Utility';
import { BUY_TYPE } from '../../Constants/Constant';
import { FULFILLMENT_STATUS } from '../Automation/AutomationConstants';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { FULFILLMENT_TYPE } from '../../Constants/Constant';
import { LABELS } from '../../Constants/Constant';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../Redux/Hooks';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { DraftTypes } from '../../Models/Drafts';
import ExpenseService from '../../Services/Expense';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import ExpenseDepositForm from '../Bank/ExpenseDeposit/ExpenseDepositForm';
import CreateAndEditNotes from '../Accounting/CreateAndEditNotes/CreateAndEditNotes';
import DebitService from '../../Services/Debit';
import CreditNoteService from '../../Services/CreditNote';
import DepositService from '../../Services/Deposit';
import InvoiceService from '../../Services/Invoice';
import AddContact from '../Contacts/AddContact';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import { CONTACT_FORM_TAB } from '../../Constants/Enum';
import AddJournalNormal from '../Accounting/JournalEntry/AddJournalNormal';
import JournalService, { JournalAPIConfig } from '../../Services/Journal';
import { selectedAccounts } from '../../Redux/Slices/AccountsSlice';
import AddJournalFundTransfer from '../Accounting/JournalEntry/AddJournalFundTransfer';
import { TRANSFER_TYPES_KEYS } from '../../Models/JournalEntry';
import PaymentRecords from '../../SharedComponents/PaymentRecords/PaymentRecords';
import SalesOrderService from '../../Services/SalesOrder';
import { getUpdatedSalesOrderObject } from '../SalesOrders/SalesOrderHelper';
import WorkOrderService from '../../Services/MRP/WorkOrder';
import WarehouseService from '../../Services/Warehouse';
import CreateNewWarehouse from '../Warehouse/CreateNewWarehouse';
import CreateProductView from '../Product/CreateProductView';
import ProductService from '../../Services/Product';
import AddNewWorkOrder from '../Mrp/WorkOrder/AddWorkOrder';
import MRPProductsService, {
  MRPProductsAPIConfig
} from '../../Services/MRP/MRPProducts';
import CreateMRPProductView from '../Mrp/BOM_Assembly/CreateMrpProduct/CreateMRPProductView';
import CreateStockAdjustment from '../StockManagement/StockAdjustment/CreateStockAdjustment';
import StockAdjustmentService from '../../Services/StockAdjustment';
import AddPartyJournal from '../Accounting/JournalEntry/AddPartyJournal';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import {
  getUpdatedFAPurchaseOrderObject,
  getUpdatedPurchaseOrderObject
} from '../PurchaseOrders/PurchaseOrderHelper';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import WorkoutService from '../../Services/Workout';
import { getJobWorkOutObject } from '../Mrp/Workout/WorkOutHelper';
import { selectWorkOutsColumnConfigTableId } from '../../Redux/Slices/WorkOutSlice';
import { getUpdatedRequisitonNewOrderObject } from '../Requisitions/RequisitionHelper';
import RequisitionService from '../../Services/Requisition';
import StockRequestService from '../../Services/StockRequest';
import AddStockRequestPopup from '../StockManagement/StockRequest/AddStockRequest';
import { getGateEntryByCode } from '../../Services/SecurityGateEntry';
import AddSecurityGateEntry from '../SecurityGateEntry/AddSecurityGateEntry';

export default function DetailsOpener(props: any) {
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };

  const dispatch = useDispatch();
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const tableId = useAppSelector(draftTableId);
  const accountsData = useAppSelector(selectedAccounts);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const jwoColumnConfigTableId = useAppSelector(
    selectWorkOutsColumnConfigTableId
  );
  const documentType = props.data.documentType;
  const data = props.data;
  const [object, setObject] = useState<any>();
  const depositRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const createGateEntryRef = useRef<UpdateCallBacksRefType>(refInitialState);

  useEffect(() => {
    if (!Utility.isEmpty(props.data)) {
      fetchData();
    }
    return () => {};
  }, [props.data]);

  const fetchData = () => {
    switch (documentType) {
      case DOC_TYPE.Direct_S_Expense:
        getExpenseFormData();
        break;
      case DOC_TYPE.Prepayment:
        getExpenseFormData();
        break;
      case DOC_TYPE.Direct_S_Deposit:
        getDepositFormData();
        break;
      case DOC_TYPE.Advance_S_Payment:
        getDepositFormData();
        break;
      case DOC_TYPE.Debit_S_Note:
      case DOC_TYPE.DEBIT_NOTE:
        getDebitNoteData();
        break;
      case DOC_TYPE.Credit_S_Note:
      case DOC_TYPE.CREDIT_NOTE:
        getCreditNoteData();
        break;
      case DOC_TYPE.Contact:
        getContactData();
        break;
      case DOC_TYPE.Journal_S_Entry:
      case DOC_TYPE.ALLOCATION:
      case DOC_TYPE.PARTY_JE:
      case 'Party JE':
        getJEDataWithHaveData();
        break;
      case DOC_TYPE.MAKE_PAYMENT:
        getMakePaymentRecords();
        break;
      case DOC_TYPE.RECEIVE_PAYMENT:
        getReceivePaymentRecords();
        break;
      case DOC_TYPE.WORK_ORDER:
        getWorkOrderData();
        break;
      case DOC_TYPE.Warehouse:
        getWarehouseData();
        break;
      case DOC_TYPE.PRODUCT:
        getProductData();
        break;
      case DOC_TYPE.MRP_PRODUCT:
        getMRPProductData();
        break;
      case DOC_TYPE.ADJUSTMENT:
        getStockAdjustment();
        break;
      case DOC_TYPE.ORDER:
        getPurchaseOrderPopup();
        break;
      case DOC_TYPE.PURCHASE_REQUEST:
        getPurchaseRequisitionPopup();
        break;
      case DOC_TYPE.STOCK_REQUEST:
      case 'STOCK_REQUEST_DRAFT':
        getStockRequestPopupData();
        break;
      case DOC_TYPE.GATE_ENTRY:
        getSecurityGateEntryPopupData();
        break;
    }
  };

  /**
   * Requires:
   * data: { documentCode, targetWarehouse }
   */
  const getStockRequestPopupData = () => {
    try {
      if (documentType === DOC_TYPE.STOCK_REQUEST)
        StockRequestService.getStockRequestById(props.data.documentCode).then(
          (stockReqData: any) => {
            const items = stockReqData.stockRequestItems.map((item: any) => {
              let data = {
                productName: item.product.name,
                lineNumber: item.lineNumber,
                productVariantCode: item.productCode,
                documentSequenceCode: item.documentSequenceCode,
                documentUom: item.documentUom,
                documentUOMSchemaDefinition:
                  props.data?.documentUOMSchemaDefinition ??
                  item.documentUOMSchemaDefinition,
                availableQuantity: item?.uomQuantity ?? item.productQuantity,
                targetWarehouse: props.data.targetWarehouse,
                dstWarehouseCode: item.dstWarehouseCode,
                product: item.product,
                rowButtons: [],
                row: {
                  code: item?.dstRowCode,
                  name: item?.dstRowName
                },
                rack: {
                  code: item?.dstRackCode,
                  name: item?.dstRackName
                },
                bin: {
                  code: item?.dstBinCode,
                  name: item?.dstBinName
                },
                customField: item.customField
              };
              return data;
            });
            const srData = {
              stockRequestDate: stockReqData.stockRequestDate,
              documentSequenceCode: stockReqData?.documentSequenceCode,
              stockRequestCode: stockReqData.stockRequestCode,
              memo: stockReqData.memo,
              stockRequestNumber: 'STKRQ-' + stockReqData.stockRequestCode,
              id: stockReqData.id,
              customField: stockReqData.customField,
              stockRequestItems: items
            };
            setObject(srData);
            removeLoader();
          },
          (err) => {
            console.error('Error while fetching document details: ', err);
            removeLoader();
          }
        );
      else {
        StockRequestService.getStockRequestDraftById(
          props.data.documentCode
        ).then(
          (stockReqData: any) => {
            const items = stockReqData.stockRequestItems.map((item: any) => {
              let data = {
                productName: item.product.name,
                lineNumber: item.lineNumber,
                productVariantCode: item.productCode,
                documentSequenceCode: item.documentSequenceCode,
                documentUom: item.documentUom,
                documentUOMSchemaDefinition:
                  props.data?.documentUOMSchemaDefinition ??
                  item.documentUOMSchemaDefinition,
                availableQuantity: item?.uomQuantity ?? item.productQuantity,
                targetWarehouse: props.data.targetWarehouse,
                dstWarehouseCode: item.dstWarehouseCode,
                product: item.product,
                rowButtons: [],
                row: {
                  code: item?.dstRowCode,
                  name: item?.dstRowName
                },
                rack: {
                  code: item?.dstRackCode,
                  name: item?.dstRackName
                },
                bin: {
                  code: item?.dstBinCode,
                  name: item?.dstBinName
                },
                customField: item.customField
              };
              return data;
            });
            const srData = {
              stockRequestDate: stockReqData.stockRequestDate,
              documentSequenceCode: stockReqData?.documentSequenceCode,
              stockRequestCode: stockReqData.stockRequestCode,
              memo: stockReqData.memo,
              stockRequestNumber: 'STKRQ-' + stockReqData.stockRequestCode,
              id: stockReqData.id,
              customField: stockReqData.customField,
              stockRequestItems: items
            };
            setObject(srData);
            removeLoader();
          },
          (err) => {
            console.error('Error while fetching document details: ', err);
            removeLoader();
          }
        );
      }
    } catch (err) {}
  };

  const getStockAdjustment = () => {
    showLoader('Loading...');
    StockAdjustmentService.getStockAdjustmentByCode(data?.documentSeqCode)
      .then((response: any) => {
        removeLoader();
        if (response?.content?.[0]) {
          let stockAdjustment = deepClone(response?.content?.[0]);
          setObject({
            ...stockAdjustment,
            adjustmentType: [stockAdjustment.adjustmentType],
            formattedAdjustmentDate: new Date(stockAdjustment.adjustmentDate)
          });
        } else {
          props.onCancel();
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getWorkOrderData = () => {
    showLoader('Loading...');
    WorkOrderService.getWorkOrderByCode(data?.documentSeqCode)
      .then((response: any) => {
        removeLoader();
        if (response?.content?.[0]) {
          setObject(response?.content?.[0]);
        } else {
          props.onCancel();
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getReceivePaymentRecords = () => {
    let documentCode = data.documentCode;
    showLoader('Loading...');
    DepositService.getDepositByCodde(documentCode)
      .then((data: any) => {
        removeLoader();
        let mappedData = data?.content?.map((item: any) => {
          return {
            ...item,
            documentCode: item?.code ?? '',
            documentDate: item?.referenceDate ?? '',
            documentType: 'RECEIVE_PAYMENT'
          };
        });
        if (Utility.isEmpty(mappedData)) {
          props.onCancel();
        } else {
          setObject(mappedData);
        }
      })
      .catch((err) => {
        removeLoader();
        props.onCancel();
      });
  };

  const getProductData = () => {
    showLoader('Loading...');
    ProductService.getProductsByProductIds([data?.documentSeqCode])
      .then((response: any) => {
        removeLoader();
        if (response?.[0]) {
          setObject(response?.[0]);
        } else {
          props.onCancel();
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getMRPProductData = () => {
    showLoader('Loading...');
    let apiConfig: MRPProductsAPIConfig = {
      ...MRPProductsService.apiConfig,
      FetchAttachmentDetails: true
    };
    MRPProductsService.apiConfig = apiConfig;
    MRPProductsService.getMRPProductByCode(data?.documentSeqCode)
      .then((response: any) => {
        removeLoader();
        if (response?.content?.[0]) {
          if (response?.content?.length > 1) {
            let prod = response?.content?.find(
              (ele: any) => ele.documentSequenceCode === data?.documentSeqCode
            );
            setObject(Utility.isNotEmpty(prod) ? prod : response?.content?.[0]);
          } else {
            setObject(response?.content?.[0]);
          }
        } else {
          props.onCancel();
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getWarehouseData = () => {
    showLoader('Loading...');
    WarehouseService.getWarehouseByCode(data?.documentSeqCode)
      .then((response: any) => {
        removeLoader();
        if (response?.content?.[0]) {
          setObject(response?.content?.[0]);
        } else {
          props.onCancel();
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getMakePaymentRecords = () => {
    let documentCode = data.documentCode;
    showLoader('Loading...');
    ExpenseService.getExpenseByCode(documentCode)
      .then((data: any) => {
        removeLoader();
        let mappedData = data?.content?.map((item: any) => {
          return {
            ...item,
            documentCode: item?.code ?? '',
            documentDate: item?.referenceDate ?? '',
            documentType: 'MAKE_PAYMENT'
          };
        });
        if (Utility.isEmpty(mappedData)) {
          props.onCancel();
        } else {
          setObject(mappedData);
        }
      })
      .catch((err) => {
        removeLoader();
        props.onCancel();
      });
  };

  const getIsReadOnly = (docClicked: any) => {
    let isReadOnly = false;

    if (
      docClicked.linkedSalesInvoices?.length ||
      docClicked.linkedSalesOrders?.length ||
      docClicked.fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED ||
      GranularPermissionsHelper.hasDocumentReadOnly(
        PERMISSIONS_BY_MODULE.QUOTATION
      )
    ) {
      isReadOnly = true;
    }
    if (
      docClicked.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP ||
      docClicked.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP
    ) {
      isReadOnly = true;
    }
    if (docClicked.reservedStock) {
      isReadOnly = true;
    }
    let res = Utility.validationForClosedDate(docClicked);
    if (res.isDisable) {
      isReadOnly = res.isDisable;
    }

    return isReadOnly;
  };

  const getBillPopup = () => {
    let documentCode = data.documentCode;
    showLoader('Loading...');
    BillService.getBillDetailsByCode(documentCode).then(
      (data: any) => {
        let docDetailsData: any = {};
        if (data.purchaseInvoiceType === 'ASSET') {
          docDetailsData = getUpdatedFABillObject(data);
        } else {
          docDetailsData = getUpdatedBillObject(data);
        }
        // const isReadOnly = getIsReadOnly(docDetailsData);
        if (!Utility.isEmpty(docDetailsData)) {
          if (
            docDetailsData &&
            docDetailsData.buyType !== null &&
            docDetailsData.buyType === 'ASSET'
          ) {
            docDetailsData.documentType = DOC_TYPE.FA_BILL;
          }
          let payloadData: any = {
            id: docDetailsData.id,
            type:
              data.purchaseInvoiceType === BUY_TYPE.INVENTORY
                ? LABELS.BILLS
                : data.purchaseInvoiceType === BUY_TYPE.ASSET
                ? LABELS.FA_BILL
                : LABELS.EXPENSE_BILL,
            title:
              data.purchaseInvoiceType === BUY_TYPE.INVENTORY
                ? LABELS.BILLS
                : data.purchaseInvoiceType === BUY_TYPE.ASSET
                ? LABELS.FA_BILL
                : LABELS.EXPENSE_BILL,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: docDetailsData,
            isSaved: true,
            tableId,
            draftsColumnConfig,
            hideMinimizer: true
          };

          dispatch(
            createBlankDraft({
              payloadData,
              draftType: DraftTypes.READONLY
            })
          );
          props.onCancel();
        }
        removeLoader();
      },
      (err) => {
        console.error('Error while fetching document details: ', err);
        removeLoader();
      }
    );
  };

  const getInvoicePopup = () => {
    let documentCode = data.documentCode;
    showLoader('Loading...');
    InvoiceService.getInvoiceByCode(documentCode).then(
      (data: any) => {
        removeLoader();
        if (!Utility.isEmpty(data)) {
          let payloadData: any = {
            type: LABELS.INVOICES,
            title: LABELS.INVOICES,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: data,
            isSaved: true,
            tableId: tableId,
            columnConfig: draftsColumnConfig,
            hideMinimizer: true
          };

          dispatch(
            createBlankDraft({ payloadData, draftType: DraftTypes.READONLY })
          );
          props.onCancel();
        }
      },
      (err) => {
        removeLoader();
        console.error('Error loading detailed invoice: ', err);
      }
    );
  };

  const getDebitNoteData = () => {
    showLoader('Loading...');
    DebitService.getDebitNoteWithCode(data.documentSeqCode)
      .then((response: any) => {
        if (response?.content?.[0]) {
          setObject(response?.content?.[0]);
        } else {
          props.onCancel();
        }
        removeLoader();
      })
      .catch((err: any) => {});
  };

  const getCreditNoteData = () => {
    showLoader('Loading...');
    CreditNoteService.getCreditNoteWithCode(data.documentSeqCode)
      .then((response: any) => {
        if (response?.content?.[0]) {
          setObject(response?.content?.[0]);
        } else {
          props.onCancel();
        }
        removeLoader();
      })
      .catch((err: any) => {});
  };

  const getContactData = () => {
    showLoader();
    const config: ContactAPIConfig = {
      ...ContactService.apiConfig,
      SearchTerm: '',
      Limit: 25,
      IncludeOpeningAmounts: true,
      IncludeOweAmounts: true,
      QueryParam: {},
      SortDir: 'DESC',
      Sort: 'documentSequenceCode',
      Page: 0,
      Query: `code=${data?.documentSeqCode}`
    };
    showLoader('Loading...');
    ContactService.apiConfig = config;
    ContactService.getContactsByPage()
      .then((response: any) => {
        removeLoader();
        if (response?.content?.[0]) {
          setObject(response?.content?.[0]);
        } else {
          props.onCancel();
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getJEData = () => {
    let config: JournalAPIConfig = {
      ...JournalService.apiConfig,
      Page: 0,
      Query: `jeCode=${data?.documentSeqCode}`
    };
    JournalService.apiConfig = config;
    showLoader('Loading...');
    JournalService.getJournal()
      .then((response: any) => {
        removeLoader();
        if (response?.content?.[0]) {
          setObject(response?.content?.[0]);
        } else {
          props.onCancel();
        }
        let config: JournalAPIConfig = {
          ...JournalService.apiConfig,
          Page: 0,
          SearchTerm: '',
          Query: ''
        };
        JournalService.apiConfig = config;
        removeLoader();
      })
      .catch((err: any) => {
        removeLoader();
        props.onCancel();
      });
  };
  const getJEDataWithHaveData = () => {
    if (props.data?.haveData) {
      setObject(props.data.haveData);
    } else {
      getJEData();
    }
  };
  const getExpenseFormData = () => {
    showLoader('Loading...');
    ExpenseService.getExpenseByCode(data.documentSeqCode)
      .then((response: any) => {
        removeLoader();
        setObject(response?.content?.[0]);
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getDepositFormData = () => {
    showLoader('Loading...');
    DepositService.getDepositByCodde(data.documentSeqCode)
      .then((response: any) => {
        removeLoader();
        setObject(response?.content?.[0]);
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getPurchaseOrderPopup = () => {
    let documentCode = data.documentCode;
    showLoader('Loading...');
    PurchaseOrderService.getPODetailsByCode(documentCode).then(
      (data: any) => {
        let docDetailsData: any = {};
        if (data.orderType === 'ASSET') {
          docDetailsData = getUpdatedFAPurchaseOrderObject(data);
        } else {
          docDetailsData = getUpdatedPurchaseOrderObject(data);
        }
        const customFieldCheck = !(
          tenantInfo?.additionalSettings?.PO_EDIT_SETTING || false
        );
        const isReadOnly =
          getIsReadOnly(docDetailsData) &&
          customFieldCheck &&
          (props.isReadOnly ?? true);
        if (!Utility.isEmpty(docDetailsData)) {
          if (
            docDetailsData &&
            docDetailsData.buyType !== null &&
            docDetailsData.buyType === 'ASSET'
          ) {
            docDetailsData.documentType = DOC_TYPE.FA_ORDER;
          }
          let payloadData: any = {
            id: docDetailsData.id,
            type:
              data.orderType === BUY_TYPE.ASSET
                ? LABELS.FA_ORDER
                : LABELS.PURCHASE_ORDERS,
            title:
              data.orderType === BUY_TYPE.ASSET
                ? LABELS.FA_ORDER
                : LABELS.PURCHASE_ORDERS,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: docDetailsData,
            isSaved: true,
            tableId,
            draftsColumnConfig,
            hideMinimizer: true
          };
          const type =
            isReadOnly ||
            ((props?.data?.isWOLinkedRecord || props?.data?.isLinkedRecord) &&
              [
                RECEIVED_GOODS_STATUS.FULLY_RECEIVED,
                RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED
              ].includes(docDetailsData.receiptStatus))
              ? // || !Utility.isEmpty(data.linkedDocuments)
                DraftTypes.READONLY
              : DraftTypes.UPDATE;
          dispatch(
            createBlankDraft({
              payloadData,
              draftType: type
            })
          );
          props.onCancel();
        }
        removeLoader();
      },
      (err) => {
        console.error('Error while fetching document details: ', err);
        removeLoader();
      }
    );
  };

  const getJobWorkOutPopup = () => {
    let documentCode = data.documentCode;
    showLoader('Loading...');
    WorkoutService.jobWorkOutDetailByCode(documentCode).then(
      (data: any) => {
        let docDetailsData: any = getJobWorkOutObject(data);
        if (props?.data?.isWOLinkedRecord) {
          docDetailsData.showBillCustomAlert = true;
        }
        if (!Utility.isEmpty(docDetailsData)) {
          let payloadData: any = {
            id: docDetailsData.id,
            type: LABELS.WORK_OUT,
            title: LABELS.WORK_OUT,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: docDetailsData,
            isSaved: true,
            tableId: jwoColumnConfigTableId,
            draftsColumnConfig,
            hideMinimizer: true
          };
          dispatch(
            createBlankDraft({
              payloadData,
              draftType:
                !!props.isReadOnly ||
                ((props?.data?.isWOLinkedRecord ||
                  props?.data?.isLinkedRecord) &&
                  (docDetailsData.dispatchStatus ===
                    JWO_DISPATCH_STATUS.DISPATCHED ||
                    docDetailsData.dispatchStatus ===
                      JWO_DISPATCH_STATUS.PENDING_DISPATCH_APPROVAL)) ||
                [
                  JWO_RECEIPT_STATUS.FULLY_RECEIVED,
                  JWO_RECEIPT_STATUS.PARTIAL_RECEIVED
                ].includes(docDetailsData.receiptStatus)
                  ? DraftTypes.READONLY
                  : DraftTypes.UPDATE
            })
          );
          props.onCancel();
        }
        removeLoader();
      },
      (err) => {
        console.error('Error while fetching document details: ', err);
        removeLoader();
      }
    );
  };

  const getSecurityGateEntryPopupData = () => {
    try {
      getGateEntryByCode(props.data.documentCode).then(
        (stockReqData: any) => {
          setObject(stockReqData);
          removeLoader();
        },
        (err) => {
          console.error('Error while fetching document details: ', err);
          removeLoader();
        }
      );
    } catch (err) {}
  };

  const catchClicks1 = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.onCancel();
        break;
      case POPUP_CLICK_TYPE.SAVE_SECURITY_GATE_ENTRY:
        createGateEntryRef?.current?.storeCallbacksRef?.saveGateEntry();
        break;
    }
  };

  const paymentRecordsBtnConfig: BtnType[] = [
    {
      title: `Close`,
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const getPaymentRecordsPopup = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks1}
        type={POPUP_TYPE.POPUP}
        title={Utility.convertInTitleCase(
          data.documentType.replaceAll('_', ' ')
        )}
        btnList={paymentRecordsBtnConfig}
        width={'90%'}
        height="500px"
        disableClickOutside={true}
        maxHeight={'90%'}
      >
        <PaymentRecords
          documentSequenceCode={data.documentSeqCode}
          paymentRecordList={object}
          documentType={
            data.documentType === DOC_TYPE.MAKE_PAYMENT
              ? DOC_TYPE.BILL
              : DOC_TYPE.INVOICE
          }
          documentCode={data.documentCode}
          contactCode={data?.contactCode}
          closePopup={() => {
            props.onCancel();
          }}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          isDocumentEmailFlow={true}
          isActionDisabled={true}
        />
      </PopupWrapper>
    );
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        props.onCancel();
        break;
      case POPUP_CLICK_TYPE.UPDATE_JE:
        props.onAllocationJECreate({});
        break;
      case POPUP_CLICK_TYPE.CREATE_DEPOSIT:
        depositRef.current?.storeCallbacksRef.createDeposit();
        break;
      case POPUP_CLICK_TYPE.UPDATE_DEPOSIT:
        depositRef.current?.storeCallbacksRef.updateDeposit();
        break;
      case POPUP_CLICK_TYPE.CREATE_ADVANCED_PAYMENT:
        depositRef.current?.storeCallbacksRef.createAdvancedPayment();
        break;
      case POPUP_CLICK_TYPE.CREATE_BANK_DEPOSIT:
        depositRef.current?.storeCallbacksRef.createBankDeposit();
        break;
      case POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT:
        depositRef.current?.storeCallbacksRef.createExpenseDeposit();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        props.onCancel();
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_SECURITY_GATE_ENTRY:
        createGateEntryRef.current.storeCallbacksRef.saveGateEntry =
          passingData.data;
        break;
      default:
        break;
    }
  };

  const popupUpdateBtnConfigView: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const getExpenseDepositFormType = (formType: string) => {
    if (formType === 'Direct Expense') {
      return DOC_TYPE.EXPENSE;
    }
    if (formType === 'Prepayment') {
      return DOC_TYPE.PREPAYMENT;
    }
    if (formType === 'Direct Deposit') {
      return DOC_TYPE.DEPOSIT;
    }
    if (formType === 'Advance Payment') {
      return DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT;
    }
  };

  const getExpenseDepositForm = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={documentType}
        btnList={popupUpdateBtnConfigView}
        isActionBtnDisabled={false}
        readOnly={true}
        disableClickOutside={true}
        allowFullScreen
      >
        <ExpenseDepositForm
          formType={getExpenseDepositFormType(documentType)}
          populateData={object}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          recordCreate={(data: any) => {}}
          allowFullScreen
        />
      </PopupWrapper>
    );
  };

  const popupBtnConfigView: BtnType[] = [
    {
      title: 'Close',
      class: 'bg-gray2 border-m mr-r',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const securityGatePopupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'bg-gray1 border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-s',
      clickAction: POPUP_CLICK_TYPE.SAVE_SECURITY_GATE_ENTRY
    }
  ];

  const getCreaditOrDebitNote = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={documentType}
        disableClickOutside={true}
        readOnly={true}
        btnList={popupBtnConfigView}
        isActionBtnDisabled={false}
        height={'98%'}
        allowFullScreen
      >
        <CreateAndEditNotes
          documentMode={DOCUMENT_MODE.VIEW}
          readOnly={true}
          populateFormData={object}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          notesType={object && object.dnDate && !Utility.isEmpty(object.dnDate) ? DOC_TYPE.DEBIT_NOTE : DOC_TYPE.CREDIT_NOTE}
          showLinkedDocPopup={(data: any) => {
            // setLinkedDocuments(editableCredit);
            // setShowLinkedDocPopup(true);
          }}
          allowFullScreen
        />
      </PopupWrapper>
    );
  };

  const getContactPopup = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={'View Contact'}
        btnList={popupBtnConfigView}
        disableClickOutside={true}
        width={'40%'}
        minWidth={'550px'}
        height={'90%'}
      >
        <AddContact
          activeTab={CONTACT_FORM_TAB.GENERAL_INFO}
          contactMode={DOCUMENT_MODE.VIEW}
          populateFormData={object}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    );
  };

  const getJEPopup = () => {
    if (
      data?.jeType === TRANSFER_TYPES_KEYS.BANK_TRANSFER ||
      data?.jeType === TRANSFER_TYPES_KEYS.CHEQUE ||
      data?.jeType === TRANSFER_TYPES_KEYS.CARD
    ) {
      return (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Journal Entry - Fund Transfer'}
          btnList={popupBtnConfigView}
          width={'695px'}
          disableClickOutside
          isActionBtnDisabled={false}
        >
          <AddJournalFundTransfer
            je={object}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCopy={false}
            readOnly={true}
            disableClickOutside
          />
        </PopupWrapper>
      );
    } else if (
      data?.documentType === DOC_TYPE.PARTY_JE ||
      data?.documentType === 'Party JE'
    ) {
      let inlineGridList = { ...object };
      let inlineGridItemsList: any[] = [];
      inlineGridList.lineItems.forEach((item: any) => {
        let initialGridItem: any = { ...item };
        const acc =
          accountsData &&
          accountsData.content &&
          accountsData.content.length > 0 &&
          accountsData.content.find(
            (account: any) => account.code === item.accountCode
          );
        initialGridItem.account = acc ? acc : null;
        initialGridItem.type = item.cdType;
        initialGridItem.contact = {
          name: item.contactName,
          code: item.contactCode
        };

        if (initialGridItem.generateCN === true) {
          initialGridItem.generateCNDN = GENERATE_CN_DN_LIST.GENERATE_CN;
        } else if (initialGridItem.generateDN === true) {
          initialGridItem.generateCNDN = GENERATE_CN_DN_LIST.GENERATE_DN;
        } else {
          initialGridItem.generateCNDN = GENERATE_CN_DN_LIST.NONE;
        }
        inlineGridItemsList.push(initialGridItem);
      });

      inlineGridList.lineItems = inlineGridItemsList;
      inlineGridList.taxAdjustmentDetails = {
        taxAdjustmentType: {
          selectedValue: '',
          options: []
        },
        taxAdjustmentSubType: {
          selectedValue: '',
          options: []
        },
        taxDetails: {},
        showAddTaxAdjustmentPopup: false
      };
      return (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Journal Entry - Party'}
          btnList={popupBtnConfigView}
          width={'70%'}
          disableClickOutside
          isActionBtnDisabled={false}
          height={'98%'}
        >
          <AddPartyJournal
            je={inlineGridList}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCopy={false}
            readOnly={true}
          />
        </PopupWrapper>
      );
    } else {
      let inlineGridList = { ...object };
      let inlineGridItemsList: any[] = [];
      inlineGridList.lineItems.forEach((item: any) => {
        let initialGridItem: any = { ...item };
        const acc =
          accountsData &&
          accountsData.content &&
          accountsData.content.length > 0 &&
          accountsData.content.find(
            (account: any) => account.code === item.accountCode
          );
        initialGridItem.account = acc ? acc : null;
        initialGridItem.type = item.cdType;
        inlineGridItemsList.push(initialGridItem);
      });

      inlineGridList.lineItems = inlineGridItemsList;
      inlineGridList.taxAdjustmentDetails = {
        taxAdjustmentType: {
          selectedValue: '',
          options: []
        },
        taxAdjustmentSubType: {
          selectedValue: '',
          options: []
        },
        taxDetails: {},
        showAddTaxAdjustmentPopup: false
      };

      if (documentType === DOC_TYPE.ALLOCATION) {
        if (props.data?.allowSave) {
          popupBtnConfigView.push({
            title: 'Save',
            class: 'bg-app border-m text-white border-transparent',
            clickAction: POPUP_CLICK_TYPE.UPDATE_JE
          });
        }
      }
      return (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`Journal Entry - ${props?.title ?? 'Normal'}`}
          btnList={popupBtnConfigView}
          width={'70%'}
          disableClickOutside
          isActionBtnDisabled={false}
          height={'98%'}
        >
          <AddJournalNormal
            je={inlineGridList}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCopy={false}
            readOnly={true}
          />
        </PopupWrapper>
      );
    }
  };

  const getSOPopup = async () => {
    const code = data?.documentCode;
    const seqCode = data?.documentSeqCode;
    showLoader();
    try {
      if (
        Utility.isEmpty(data?.documentCode) &&
        Utility.isEmpty(data?.documentSeqCode)
      )
        return;
      let salesOrder: any = {};
      if (code) {
        salesOrder = await SalesOrderService.getSalesOrderByCode(code);
      }
      if (seqCode) {
        salesOrder = await SalesOrderService.getSalesOrderByDocSeqCode(seqCode);
      }
      const salesOrderDetailsData: any = getUpdatedSalesOrderObject(salesOrder);
      if (!Utility.isEmpty(salesOrderDetailsData)) {
        removeLoader();
        let payloadData: any = {
          id: salesOrderDetailsData.id,
          type: LABELS.SALES_ORDER,
          title: LABELS.SALES_ORDER,
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: salesOrderDetailsData,
          isSaved: true,
          tableId,
          draftsColumnConfig,
          hideMinimizer: true
        };
        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
        props.onCancel();
      }
    } catch (err: any) {
      removeLoader();
      console.error('Error loading linked sales order: ', err);
    }
  };

  const getWarehousePopup = () => {
    let warehousedata = deepClone(object);
    return (
      <CreateNewWarehouse
        editableWarehouse={{ ...warehousedata, isDeleteEnable: false }}
        isReadOnly={true}
        onCancel={() => {
          props.onCancel();
        }}
        onUpdate={() => {
          props.onCancel();
        }}
      />
    );
  };

  const getProductPopup = () => {
    return (
      <CreateProductView
        product={object}
        passingInteraction={(callback: CallBackPayloadType) => {}}
        isCopy={false}
        readOnly={true}
        onCancel={() => {
          props.onCancel();
        }}
      />
    );
  };

  const getMRPProductPopup = () => {
    return (
      <CreateMRPProductView
        product={object}
        isCopy={false}
        readOnly={true}
        onCancel={() => {
          props.onCancel();
        }}
      />
    );
  };

  const getStockAdjustmentPopup = () => {
    return (
      <CreateStockAdjustment
        readOnly={true}
        data={object}
        onSave={() => {
          props.onCancel();
        }}
        onCancel={() => {
          props.onCancel();
        }}
        allowFullScreen
      />
    );
  };

  const getStockRequestPopup = () => {
    return (
      <AddStockRequestPopup
        readOnly={true}
        records={[]}
        selectedRecord={object}
        onSave={() => {}}
        onCancel={() => setObject(null)}
      />
    );
  };

  const getSecurityGatePopup = () => {
    return (
      <PopupWrapper
        disableClickOutside={true}
        clickAction={catchClicks1}
        readOnly={false}
        btnList={securityGatePopupBtnConfig}
        // loadingBtnList={loadingBtnConfig}
        // isLoading={entryBeingSaved}
        type={POPUP_TYPE.POPUP}
        title={`Edit Security Gate Entry`}
        height={'80%'}
        width={'60%'}
        maxWidth={'100%'}
        allowFullScreen
      >
        <AddSecurityGateEntry
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          gateEntry={object}
          onSave={() => {
            props.onCancel();
            setObject(null);
          }}
          onError={(err: any) => {
            setObject(null);
          }}
        />
      </PopupWrapper>
    );
  };

  const getWorkOrderPopup = () => {
    let woData = { ...object };
    if (props?.data?.isWOLinkedRecord) {
      woData.isWOLinkedRecord = true;
    }
    return (
      <AddNewWorkOrder
        workOrder={woData}
        isReadOnly={props.isReadOnly ?? true}
        onClose={() => {
          props.onCancel();
        }}
        onSave={(response: any) => {
          props.onCancel();
        }}
        continueInEditMode={(res: any, selectedTabIndex = 0) => {
          props.onCancel();
        }}
        hideContinueButtonAfterSave={true}
      />
    );
  };

  const getPurchaseRequisitionPopup = async () => {
    const code = data?.documentCode;
    showLoader();
    try {
      if (Utility.isEmpty(data?.documentCode)) return;
      let prData: any = {};
      if (code) {
        prData = await RequisitionService.fetchOrderDetails(code);
      }

      prData = getUpdatedRequisitonNewOrderObject(prData);
      removeLoader();
      let payloadData: any = {
        id: prData.id,
        type: LABELS.REQUISITION,
        title: LABELS.REQUISITION,
        isMaximized: true,
        isCenterAlign: true,
        populateFormData: prData,
        isSaved: true,
        tableId,
        draftsColumnConfig,
        hideMinimizer: true
      };

      dispatch(
        createBlankDraft({
          payloadData,
          draftType: DraftTypes.READONLY
        })
      );
      props.onCancel();
    } catch (err: any) {
      removeLoader();
      console.error('Error loading linked sales order: ', err);
    }
  };

  const getDocument = () => {
    switch (documentType) {
      case DOC_TYPE.Direct_S_Expense:
        return <div>{!Utility.isEmpty(object) && getExpenseDepositForm()}</div>;
      case DOC_TYPE.Prepayment:
        return <div>{!Utility.isEmpty(object) && getExpenseDepositForm()}</div>;
      case DOC_TYPE.Advance_S_Payment:
        return <div>{!Utility.isEmpty(object) && getExpenseDepositForm()}</div>;
      case DOC_TYPE.Direct_S_Deposit:
        return <div>{!Utility.isEmpty(object) && getExpenseDepositForm()}</div>;
      case DOC_TYPE.Debit_S_Note:
      case DOC_TYPE.DEBIT_NOTE:
        return <div>{!Utility.isEmpty(object) && getCreaditOrDebitNote()}</div>;
      case DOC_TYPE.Credit_S_Note:
      case DOC_TYPE.CREDIT_NOTE:
        return <div>{!Utility.isEmpty(object) && getCreaditOrDebitNote()}</div>;
      case DOC_TYPE.Bill:
      case DOC_TYPE.BILL:
      case DOC_TYPE.FIXED_ASSET_BILL:
      case DOC_TYPE.AMORTIZATION:
        getBillPopup();
        break;
      case DOC_TYPE.Invoice:
      case DOC_TYPE.INVOICE:
        getInvoicePopup();
        break;
      case DOC_TYPE.Contact:
        return <div>{!Utility.isEmpty(object) && getContactPopup()}</div>;
      case DOC_TYPE.Journal_S_Entry:
      case DOC_TYPE.ALLOCATION:
      case DOC_TYPE.PARTY_JE:
      case 'Party JE':
        return <div>{!Utility.isEmpty(object) && getJEPopup()}</div>;
      case DOC_TYPE.MAKE_PAYMENT:
      case DOC_TYPE.RECEIVE_PAYMENT:
        return (
          <div>{!Utility.isEmpty(object) && getPaymentRecordsPopup()}</div>
        );
      case DOC_TYPE.SALES_ORDER:
        getSOPopup();
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        getJobWorkOutPopup();
        break;
      case DOC_TYPE.Warehouse:
        return <div>{!Utility.isEmpty(object) && getWarehousePopup()}</div>;
      case DOC_TYPE.PRODUCT:
        return <div>{!Utility.isEmpty(object) && getProductPopup()}</div>;
      case DOC_TYPE.WORK_ORDER:
        return <div>{!Utility.isEmpty(object) && getWorkOrderPopup()}</div>;
      case DOC_TYPE.MRP_PRODUCT:
        return <div>{!Utility.isEmpty(object) && getMRPProductPopup()}</div>;
      case DOC_TYPE.ADJUSTMENT:
        return (
          <div>{!Utility.isEmpty(object) && getStockAdjustmentPopup()}</div>
        );
      case DOC_TYPE.STOCK_REQUEST:
      case 'STOCK_REQUEST_DRAFT':
        return Utility.isEmpty(object) ? null : getStockRequestPopup();
      case DOC_TYPE.GATE_ENTRY:
        return Utility.isEmpty(object) ? null : getSecurityGatePopup();
      default:
        props.onCancel();
        break;
    }
  };

  return <div>{getDocument()}</div>;
}
