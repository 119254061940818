import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class PickingListTableWidth {

    isInvoiceOrQuotationVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isBarcodeVisible: boolean;
    isQuantityToPickVisible: boolean;
    isQuantityForOrderVisible: boolean;
    isPickedVisible: boolean;
    isWarehouseCodeVisible: boolean;
    isWarehouseNameVisible: boolean;

    isSerialBatchVisible: boolean;
    isSerialNumberVisible: boolean;
    isBatchQuantityVisible: boolean;
    isBatchNumberVisible: boolean;
    isManufacturingDateVisible: boolean;
    isExpiryDateVisible: boolean;
    isSelectedRowVisible: boolean;
    isSelectedRackVisible: boolean;
    isSelectedBinVisible: boolean;
    isProductCustomFieldVisible: boolean;

    invoiceOrQuotation: number;
    code: number;
    name: number;
    barcode: number;
    quantityToPick: number;
    quantityForOrder: number;
    picked: number;
    warehouseCode: number;
    warehouseName: number;

    serialBatch: number;
    serialNumber: number;
    batchNumber: number;
    batchQuantity: number;
    manufacturingDate: number;
    expiryDate: number;
    selectedRow: number;
    selectedRack: number;
    selectedBin: number;
    productCustomField: number;

    originalInvoiceOrQuotation: number;
    originalCode: number;
    originalName: number;
    originalBarcode: number;
    originalQuantityToPick: number;
    originalQuantityForOrder: number;
    originalPicked: number;
    originalWarehouseCode: number;
    originalWarehouseName: number;

    originalSerialBatch: number;
    originalSerialNumber: number;
    originalBatchNumber: number;
    originalBatchQuantity: number;
    originalManufacturingDate: number;
    originalExpiryDate: number;
    originalSelectedRow: number;
    originalSelectedRack: number;
    originalSelectedBin: number;
    originalProductCustomField: number;

    productCustomFieldList: any;

    constructor() {
        this.isInvoiceOrQuotationVisible = true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isBarcodeVisible = true
        this.isQuantityToPickVisible = true
        this.isQuantityForOrderVisible = true
        this.isPickedVisible = true
        this.isWarehouseCodeVisible = true
        this.isWarehouseNameVisible = true

        this.isSerialBatchVisible = true
        this.isSerialNumberVisible = true
        this.isBatchNumberVisible = true
        this.isBatchQuantityVisible = true
        this.isManufacturingDateVisible = true
        this.isExpiryDateVisible = true
        this.isSelectedRowVisible = true
        this.isSelectedRackVisible = true
        this.isSelectedBinVisible = true
        this.isProductCustomFieldVisible = true

        this.invoiceOrQuotation = 12
        this.code = 10
        this.name = 15
        this.barcode = 5
        this.quantityToPick = 5
        this.quantityForOrder = 5
        this.picked = 5
        this.warehouseCode = 5
        this.warehouseName = 5

        this.serialBatch = 3
        this.serialNumber = 3
        this.batchNumber = 3
        this.batchQuantity = 3
        this.manufacturingDate = 3.5
        this.expiryDate = 3.5
        this.selectedRow = 3
        this.selectedRack = 3
        this.selectedBin = 3
        this.productCustomField = 5

        this.originalInvoiceOrQuotation = 12
        this.originalCode = 10
        this.originalName = 15
        this.originalBarcode = 5
        this.originalQuantityToPick = 5
        this.originalQuantityForOrder = 5
        this.originalPicked = 5
        this.originalWarehouseCode = 5
        this.originalWarehouseName = 5

        this.originalSerialBatch = 3
        this.originalSerialNumber = 3
        this.originalBatchNumber = 3
        this.originalBatchQuantity = 3
        this.originalManufacturingDate = 3.5
        this.originalExpiryDate = 3.5
        this.originalSelectedRack = 5
        this.originalSelectedRow = 5
        this.originalSelectedBin = 5
        this.originalProductCustomField = 5
    }

    setIsShowWidth(columnData: any) {
        this.isInvoiceOrQuotationVisible = getIsShowTableColumn(columnData, TableColumnPopupType.invoiceOrQuotation)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isBarcodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.barcode)
        this.isQuantityToPickVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityToPick)
        this.isQuantityForOrderVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityRequiredForOrder)
        this.isPickedVisible = getIsShowTableColumn(columnData, TableColumnPopupType.picked)
        this.isWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseCode)
        this.isWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseName)

        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isSerialNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialNumber)
        this.isBatchNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchNumber)
        this.isBatchQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchQuantity)
        this.isManufacturingDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.manufacturingDate)
        this.isExpiryDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.expiryDate)
        this.isSelectedRowVisible = getIsShowTableColumn(columnData, TableColumnPopupType.selectedRow)
        this.isSelectedRackVisible = getIsShowTableColumn(columnData, TableColumnPopupType.selectedRack)
        this.isSelectedBinVisible = getIsShowTableColumn(columnData, TableColumnPopupType.selectedBin)

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.invoiceOrQuotation = this.originalInvoiceOrQuotation
        this.code = this.originalCode
        this.name = this.originalName
        this.barcode = this.originalBarcode
        this.quantityToPick = this.originalQuantityToPick
        this.quantityForOrder = this.originalQuantityForOrder
        this.picked = this.originalPicked
        this.warehouseCode = this.originalWarehouseCode
        this.warehouseName = this.originalWarehouseName

        this.serialBatch = this.originalSerialBatch
        this.serialNumber = this.originalSerialNumber
        this.batchNumber = this.originalBatchNumber
        this.batchQuantity = this.originalBatchQuantity
        this.manufacturingDate = this.originalManufacturingDate
        this.expiryDate = this.originalExpiryDate
        this.selectedRow = this.originalSelectedRow
        this.selectedRack = this.originalSelectedRack
        this.selectedBin = this.originalSelectedBin

        this.productCustomField = this.originalProductCustomField

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.invoiceOrQuotation = 0
        this.code = 0
        this.name = 0
        this.barcode = 0
        this.quantityToPick = 0
        this.quantityForOrder = 0
        this.picked = 0
        this.warehouseCode = 0
        this.warehouseName = 0

        this.serialBatch = 0
        this.serialNumber = 0
        this.batchNumber = 0
        this.batchQuantity = 0
        this.manufacturingDate = 0
        this.expiryDate = 0
        this.selectedRow = 0
        this.selectedRack = 0
        this.selectedBin = 0
        this.productCustomField = 0
        
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isInvoiceOrQuotationVisible) {
            totalWidth += this.invoiceOrQuotation;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isBarcodeVisible) {
            totalWidth += this.barcode;
        }
        if (this.isQuantityToPickVisible) {
            totalWidth += this.quantityToPick;
        }
        if (this.isQuantityForOrderVisible) {
            totalWidth += this.quantityForOrder;
        }
        if (this.isPickedVisible) {
            totalWidth += this.picked;
        }
        if (this.isWarehouseCodeVisible) {
            totalWidth += this.warehouseCode;
        }
        if (this.isWarehouseNameVisible) {
            totalWidth += this.warehouseName;
        }

        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSerialNumberVisible) {
            totalWidth += this.serialNumber;
        }
        if (this.isBatchNumberVisible) {
            totalWidth += this.batchNumber;
        }
        if (this.isBatchQuantityVisible) {
            totalWidth += this.batchQuantity;
        }
        if (this.isManufacturingDateVisible) {
            totalWidth += this.manufacturingDate;
        }
        if (this.isExpiryDateVisible) {
            totalWidth += this.expiryDate;
        }
        if (this.isSelectedRowVisible) {
            totalWidth += this.selectedRow;
        }
        if (this.isSelectedRackVisible) {
            totalWidth += this.selectedRack;
        }
        if (this.isSelectedBinVisible) {
            totalWidth += this.selectedBin;
        }
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isInvoiceOrQuotationVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isBarcodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityToPickVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityForOrderVisible) {
            totalSelectedElement += 1
        }
        if (this.isPickedVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseNameVisible) {
            totalSelectedElement += 1
        }

        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isManufacturingDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isExpiryDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isSelectedRowVisible) {
            totalSelectedElement += 1
        }
        if (this.isSelectedRackVisible) {
            totalSelectedElement += 1
        }
        if (this.isSelectedBinVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isInvoiceOrQuotationVisible) {
            this.invoiceOrQuotation = this.originalInvoiceOrQuotation + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isBarcodeVisible) {
            this.barcode = this.originalBarcode + additionalWidth
        }
        if (this.isQuantityToPickVisible) {
            this.quantityToPick = this.originalQuantityToPick + additionalWidth
        }
        if (this.isQuantityForOrderVisible) {
            this.quantityForOrder = this.originalQuantityForOrder + additionalWidth
        }
        if (this.isPickedVisible) {
            this.picked = this.originalPicked + additionalWidth
        }
        if (this.isWarehouseCodeVisible) {
            this.warehouseCode = this.originalWarehouseCode + additionalWidth
        }
        if (this.isWarehouseNameVisible) {
            this.warehouseName = this.originalWarehouseName + additionalWidth
        }

        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isSerialNumberVisible) {
            this.serialNumber = this.originalSerialNumber + additionalWidth
        }
        if (this.isBatchNumberVisible) {
            this.batchNumber = this.originalBatchNumber + additionalWidth
        }
        if (this.isBatchQuantityVisible) {
            this.batchQuantity = this.originalBatchQuantity + additionalWidth
        }
        if (this.isManufacturingDateVisible) {
            this.manufacturingDate = this.originalManufacturingDate + additionalWidth
        }
        if (this.isExpiryDateVisible) {
            this.expiryDate = this.originalExpiryDate + additionalWidth
        }
        if (this.isSelectedRowVisible) {
            this.selectedRow = this.originalSelectedRow + additionalWidth
        }
        if (this.isSelectedRackVisible) {
            this.selectedRack = this.originalSelectedRack + additionalWidth
        }
        if (this.isSelectedBinVisible) {
            this.selectedBin = this.originalSelectedBin + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}