import { MODULES_NAME } from './Constant';

export const REMOTE_CONFIG_TABLES: {
  [key: string]: string;
} = {
  CONTACT: 'books_contact',
  INVOICE: 'books_invoice',
  PRODUCT: 'books_product',
  PURCHASE_ORDER: 'books_order',
  REQUISITION: 'books_requisition',
  REQUISITIONS: 'books_requisitions',
  FIXED_ASSET: 'books_fixed_asset',
  ASSET_GROUP: 'books_asset_group',
  DEPRECIATION_SCHEDULE: 'books_depreciation_schedule',
  QUOTATION: 'books_quote',
  REQUEST_FOR_QUOTATION: 'books_request_for_quotation',
  SALES_ORDER: 'books_sales_order',
  STOCK_ADJUSTMENT: 'books_stock_adjustment',
  STOCK_TRANSFER: 'books_stock_transfer',
  STOCK_ISSUE: 'books_stock_issue',
  STOCK_REQUEST: 'books_stock_request',
  BILL: 'books_bill',
  QUALITY_CHECK: 'books_quality_check',
  DRAFTS: 'draft',
  JOURNAL: 'books_journal_entry',
  COA: 'books_chart_of_accounts',
  DEPOSIT: 'books_deposit',
  EXPENSE: 'books_expense',
  FINANCIAL_YEAR_CLOSING: 'books_financial_year_closing',
  CREDIT_NOTES: 'books_credit_notes',
  DEBIT_NOTES: 'books_debit_notes',
  ACCOUNTS: 'books_accounts',
  INVENTORY: 'books_inventory',
  WAREHOUSE: 'books_warehouse',
  OB_INVOICES: 'books_ob_invoice',
  PRICE_LIST: 'books_price_list',
  PRICE_BOOK: 'books_price_book',
  BANKS: 'books_banks',
  EWAY_BILL: 'books_eway_bill',
  CLASSES: 'books_classes',
  PAYMENT_TERMS: 'books_payment_terms',
  BANK_RULES: 'books_banks_rules',
  AUDIT_LOG: 'books_audit_log',
  CURRENCY: 'books_currency',
  TAX_TABLE: 'books_settings_tax',
  UOM: 'books_uom',
  BANK_DETAILS_DK: 'books_banks_deskera_transactions',
  BANK_DETAILS_STATEMENT: 'books_banks_transactions_view_statement',
  BANK_DETAILS_TRANSACTION: 'books_banks_transactions',
  IMPORT_LOG: 'books_import_log',
  IMPORT_DRAFT: 'books_import_draft',
  UOM_SCHEMA: 'books_uom_schema',
  SALES_FULLFILLMENT_REPORT: 'books_sales_fullfillment_report',
  AUTOMATION: 'books_automation',
  PICK_PACK_SHIP_ALL_LINE_ITEM: 'allLineItems',
  PICK_PACK_SHIP_PICK_LINE_ITEM: 'pickLineItem',
  CUSTOM_FIELDS_LIST: 'books_custom_field_list',
  LOCATION_LIST: 'books_location_list',
  PRINT_CHECK: 'print_check',
  AGENCIES: 'agencies',
  BUDGETING: 'budgeting',
  CONSOLIDATION_FINANCIAL_REPORT: 'CONSOLIDATION_FINANCIAL_REPORT',
  SALES_SERVICE_DETAILS: 'SALES_SERVICE_DETAILS',
  OPERATIONS: 'mrp_operations',
  MRP_HOLIDAY_LIST: 'MRP_HOLIDAY_LIST',
  WORKSTATIONS: 'mrp_workstation',
  // WORK_ORDERS: 'MRP_WORK_ORDERS',
  WORK_ORDERS: 'mrp_work_orders',
  MRP_PRODUCTION_PLAN: 'MRP_PRODUCTION_PLAN',
  JOB_CARDS: 'JOB_CARDS',
  MRP_WORKER_LIST: 'MRP_WORKER_LIST',
  BOM_EXPLOSION: 'BOM_EXPLOSION',
  MRP_BOM_EXPLOSION: 'mrp_bom_explosion',
  BOM_EXPLOSION_SUB_GRID: 'BOM_EXPLOSION_SUB_GRID',
  MRP_PRODUCT_SUBSTITUTE: 'mrp_product_substitute',
  OPERATORS: 'mrp_operators',
  MRP_QUALITY_CHECK: 'MRP_QUALITY_CHECK',
  WORK_OUT: 'mrp_job_work_out',
  FORECAST: 'forecast',
  MASTER_FORECAST: 'master_forecast',
  PURCHASE_REQUISITION: 'books_purchase_requisition',
  MRP_REPORTS_YIELD_SUMMARY: 'MRP_REPORTS_YIELD_SUMMARY',
  MRP_REPORTS_YIELD_DETAILED_SUMMARY: 'MRP_REPORTS_YIELD_DETAILED_SUMMARY',
  MRP_REPORTS_DAILY_CONSUMPTION: 'MRP_REPORTS_DAILY_CONSUMPTION',
  AMORTIZATION_TEMPLATES: 'amortization_templates',
  REVENUE_CATEGORY_LIST: 'books_revenue_category_list',
  AMORTIZATION_SCHEDULES: 'books_amortization_schedule',
  EXPENSE_ALLOCATION_TEMPLATES: 'expense_allocation_templates',
  AUTOMATON_LOGS: 'automation_log',
  BOOKS_REPORTS_SALES_ORDER_DETAILS: 'books_report_sales_order_details',
  BOOKS_REPORT_SKU_ORDER_TRACKING: 'books_report_sku_order_tracking',
  BOOKS_REPORT_GRN_REPORT: 'books-grn-report',
  BOOKS_REPORT_FULFILLMENT_REPORT: 'books-fulfilment-report',
  BOOKS_REPORTS_MRP_MACHINE_MASTER_TOOLS:
    'books_report_mrp_machine_master_tools',
  RESTOCKING_LEVEL_REPORT: 'book_restock_level_report',
  REORDER_LEVEL_REPORT: 'book_reorder_level_report',
  BIN_ALLOCATION_REPORT: 'mrp_bin_allocation_report',
  JOB_WORKOUT_REPORT: 'mrp_job_workout_report',
  BOOKS_GOODS_RECEIPT_MASTER: 'books_goods_receipt_master',
  BOOKS_GOODS_RECEIPT_APPROVAL_LIST: 'books_goods_receipt_approval_list',
  BOOKS_FULFILLMENT_MASTER: 'books_fulfillment_master',
  BOOKS_FULFILLMENT_APPROVAL_LIST: 'books_fulfillment_approval_list',
  STOCK_ADJUSTMENT_APPROVAL_LIST: 'books_stock_adjustment_draft',
  STOCK_REQUEST_APPROVAL_LIST: 'books_stock_request_draft',
  STOCK_TRANSFER_APPROVAL_LIST: 'books_stock_transfer_draft',
  MRP_MACHINE_ASSET_MAINTAINCE_SCHEUDLE:
    'books_report_mrp_machine_asset_maintaince_schedule',
  PAYMENT_LOGS: 'books-payment-logs',
  UPLOAD_TO_FTP_LOGS: 'books-upload-to-ftp-logs',
  TDS_RATE: 'tds_rate',
  BANK_BOOK_REPORT: 'bank_book_report',
  CONSOLIDATE_BANK_BOOK_REPORT: 'consolidate_bank_book_report',
  PURCHASE_INWARD_QUOTATION: 'PURCHASE_INWARD_QUOTATION',
  RFQ_CONTACT_MASTER: 'RFQ_CONTACT_MASTER',
  MATERIAL_MASTER: 'mrp_material_master'
};

export const TABLES = {
  REV_REC: MODULES_NAME.REV_REC,
  CONTACT: MODULES_NAME.CONTACT,
  FORM_1099_CONTRACTOR: MODULES_NAME.FORM_1099_CONTRACTOR,
  INVOICE: REMOTE_CONFIG_TABLES.INVOICE,
  QUALITY_CHECK: REMOTE_CONFIG_TABLES.QUALITY_CHECK,
  PRODUCT: MODULES_NAME.PRODUCT,
  MATERIAL_MASTER: MODULES_NAME.MATERIAL_MASTER,
  PURCHASE_ORDER: REMOTE_CONFIG_TABLES.PURCHASE_ORDER,
  REQUISITION: REMOTE_CONFIG_TABLES.REQUISITION,
  FIXED_ASSET: REMOTE_CONFIG_TABLES.FIXED_ASSET,
  ASSET_GROUP: REMOTE_CONFIG_TABLES.ASSET_GROUP,
  DEPRECIATION_SCHEDULE: REMOTE_CONFIG_TABLES.DEPRECIATION_SCHEDULE,
  PURCHASE_INWARD_QUOTATION: REMOTE_CONFIG_TABLES.PURCHASE_INWARD_QUOTATION,
  SALES_ORDERS: REMOTE_CONFIG_TABLES.SALES_ORDER,
  STOCK_ADJUSTMENT: REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT,
  QUOTATION: REMOTE_CONFIG_TABLES.QUOTATION,
  REQUEST_FOR_QUOTATION: REMOTE_CONFIG_TABLES.REQUEST_FOR_QUOTATION,
  BILL: REMOTE_CONFIG_TABLES.BILL,
  BOOKS_REPORTS_SALES_ORDER_DETAILS:
    REMOTE_CONFIG_TABLES.BOOKS_REPORTS_SALES_ORDER_DETAILS,
  BOOKS_REPORT_SKU_ORDER_TRACKING:
    REMOTE_CONFIG_TABLES.BOOKS_REPORT_SKU_ORDER_TRACKING,
  BOOKS_REPORTS_MRP_MACHINE_MASTER_TOOLS:
    REMOTE_CONFIG_TABLES.BOOKS_REPORTS_MRP_MACHINE_MASTER_TOOLS,
  DRAFTS: MODULES_NAME.DRAFTS,
  SETTINGS: MODULES_NAME.SETTINGS,
  JOURNAL_ENTRY: MODULES_NAME.JOURNAL_ENTRY,
  DEBIT_NOTES: MODULES_NAME.DEBITNOTE,
  CREDIT_NOTES: MODULES_NAME.CREDITNOTE,
  COA: REMOTE_CONFIG_TABLES.COA,
  ACCOUNTING: MODULES_NAME.ACCOUNTING,
  DEPOSIT: MODULES_NAME.DEPOSIT,
  ACCOUNTS: MODULES_NAME.ACCOUNT,
  WAREHOUSE: MODULES_NAME.WAREHOUSE,
  OBINVOICES: MODULES_NAME.OB_INVOICES,
  IMPORT: MODULES_NAME.IMPORT,
  PRICE_LIST: MODULES_NAME.PRICE_LIST,
  PRICE_BOOK: MODULES_NAME.PRICE_BOOK,
  BANK: MODULES_NAME.BANK,
  REVENUE_ARRANGEMENT: MODULES_NAME.REVENUE_ARRANGEMENT,
  UPDATE_REVENUE_ARRANGEMENT: MODULES_NAME.UPDATE_REVENUE_ARRANGEMENT,
  APPS: MODULES_NAME.APPS,
  EWAY_BILL: MODULES_NAME.EWAY_BILL,
  AGENCIES: MODULES_NAME.AGENCIES,
  CLASSES: MODULES_NAME.CLASSES,
  BANK_DETAILS: MODULES_NAME.BANK_DETAILS,
  PAYMENT_TERMS: MODULES_NAME.PAYMENT_TERMS,
  EXPENSE: MODULES_NAME.EXPENSE,
  RULES: MODULES_NAME.RULES,
  AUDIT_LOG: MODULES_NAME.AUDIT_LOG,
  CURRENCY: MODULES_NAME.CURRENCY,
  TAX: MODULES_NAME.TAX,
  IMPORT_LOG: MODULES_NAME.IMPORT_LOG,
  IMPORT_DRAFT: MODULES_NAME.IMPORT_DRAFT,
  REV_REC_JE_LOGS: MODULES_NAME.REV_REC_JE_LOGS,
  UOM: MODULES_NAME.UOM,
  UOM_SCHEMA: MODULES_NAME.UOM_SCHEMA,
  TDS_RATES: MODULES_NAME.TDS_RATES,
  TDS_NATURE_OF_PAYMENT: MODULES_NAME.NATURE_OF_PAYMENT,
  TDS_DEDUCTEE_TYPE: MODULES_NAME.DEDUCTEE_TYPE,
  TCS_RATES: MODULES_NAME.TCS_RATES,
  FY_CLOSING: MODULES_NAME.FY_CLOSING,
  REPORTS: MODULES_NAME.REPORTS,
  DEBIT_REPORT: MODULES_NAME.DEBIT_REPORT,
  CREDIT_REPORT: MODULES_NAME.CREDIT_REPORT,
  INVOICE_REPORT: MODULES_NAME.INVOICE_REPORT,
  BILL_REPORT: MODULES_NAME.BILL_REPORT,
  RECONCILIATION_LOG_REPORT: MODULES_NAME.RECONCILIATION_LOG_REPORT,
  GST_REPORT_LOG: MODULES_NAME.GST_REPORT_LOG,
  ORDER_LIST_REPORT: MODULES_NAME.ORDER_REPORT,
  STOCK_MOVEMENT_REPORT: MODULES_NAME.STOCK_MOVEMENT_REPORT,
  QUOTATION_REPORT: MODULES_NAME.QUOTATION_REPORT,
  SBP_REPORT: MODULES_NAME.SALES_BY_PRODUCT,
  SBC_REPORT: MODULES_NAME.SALES_BY_CONTACT,
  FULFILLMENT_REPORT: MODULES_NAME.FULFILLMENT_REPORT,
  GOODS_RECEIVED_NOTES: MODULES_NAME.GOODS_RECEIVED_NOTES,
  PURCHASE_ORDER_DETAIL_REPORT: MODULES_NAME.PURCHASE_ORDER_DETAIL_REPORT,
  SALES_ORDER_DETAIL_REPORT: MODULES_NAME.SALES_ORDER_DETAIL_REPORT,
  FULFILLMENT_REPORT_IN: MODULES_NAME.FULFILLMENT_REPORT_IN,
  PURCHASE_BY_CONTACT: MODULES_NAME.PURCHASE_BY_CONTACT,
  PURCHASE_BY_PRODUCT: MODULES_NAME.PURCHASE_BY_PRODUCT,
  RECEIPT_LIST: MODULES_NAME.RECEIPT_LIST,
  PAYMENT_LIST: MODULES_NAME.PAYMENT_LIST,
  AGED_RECEIVABLES_REPORT: MODULES_NAME.AGED_RECEIVABLES_REPORT,
  MILESTONE_REPORT: MODULES_NAME.MILESTONE_REPORT,
  AGED_PAYABLES_REPORT: MODULES_NAME.AGED_PAYABLES_REPORT,
  VARIANT: MODULES_NAME.VARIANT,
  SALES_TAX_RETURN: MODULES_NAME.SALES_TAX_RETURN,
  SERVICE_TAX_RETURN: MODULES_NAME.SERVICE_TAX_RETURN,
  PICK_PACK_SHIP_ALL: MODULES_NAME.PICK_PACK_SHIP_ALL,
  PICK_PACK_SHIP_PENDING: MODULES_NAME.PICK_PACK_SHIP_ALL,
  PICK_PACK_SHIP_PICKING: MODULES_NAME.PICK_PACK_SHIP_ALL,
  PICK_PACK_SHIP_PACKING: MODULES_NAME.PICK_PACK_SHIP_ALL,
  PICK_PACK_SHIP_SHIPPING: MODULES_NAME.PICK_PACK_SHIP_ALL,
  AUTOMATIONS: MODULES_NAME.AUTOMATIONS,
  PICK_PACK_SHIP_ALL_LINE_ITEM: MODULES_NAME.PICK_PACK_SHIP_ALL_LINE_ITEM,
  PICK_PACK_SHIP_PICK_LINE_ITEM: MODULES_NAME.PICK_PACK_SHIP_PICK_LINE_ITEM,
  INVENTORY_TAB: MODULES_NAME.INVENTORY_TAB,
  CUSTOM_FIELDS_LIST: MODULES_NAME.CUSTOM_FIELD_LIST,
  LOCATION_LIST: MODULES_NAME.LOCATION_LIST,
  CUSTOM_REPORTS: MODULES_NAME.CUSTOM_REPORTS,
  PRINT_CHECKS: MODULES_NAME.PRINT_CHECKS,
  BOOKKEEPER_TEAM: MODULES_NAME.BOOKKEEPER_TEAM,
  BOOKKEEPER_CLIENT: MODULES_NAME.BOOKKEEPER_CLIENT,
  ITEM_LANDED_COST: MODULES_NAME.ITEM_LANDED_COST,
  ITEM_LANDED_COST_REPORT: MODULES_NAME.ITEM_LANDED_COST_REPORT,
  EWB_REPORT: MODULES_NAME.EWB_REPORTS,
  BUDGETING: MODULES_NAME.BUDGETING,
  OB_INVENTORY: MODULES_NAME.OB_INVENTORY,
  CONSOLIDATION_FINANCIAL_REPORT: MODULES_NAME.CONSOLIDATION_FINANCIAL_REPORT,
  CONSOLIDATION_FINANCIAL_REPORT_LIST:
    MODULES_NAME.CONSOLIDATION_FINANCIAL_REPORT_LIST,
  SALES_SERVICE_DETAILS: MODULES_NAME.SALES_SERVICE_DETAILS,
  OPERATIONS: MODULES_NAME.OPERATIONS,
  MRP_MACHINE_MASTER: MODULES_NAME.MACHINE_MAINTAINCE_AND_TOOLS,
  MRP_MACHINE_ASSET_MAINTAINCE_SCHEUDLE:
    MODULES_NAME.MACHINE_ASSET_MAINTAINCE_SCHEUDLE,
  MRP_MACHINE_SCHEDULING: MODULES_NAME.MACHINE_SCHEDULING,
  FORECASTING: MODULES_NAME.FORECASTING,
  MRP_QUALITY_CHECK: MODULES_NAME.MRP_QUALITY_CHECK,
  MRP_ROUTING_TEMPLATES: MODULES_NAME.MRP_ROUTING_TEMPLATES,
  MRP_REPORTS_YIELD_SUMMARY: MODULES_NAME.MRP_REPORTS_YIELD_SUMMARY,
  MRP_REPORTS_YIELD_DETAILED_SUMMARY:
    MODULES_NAME.MRP_REPORTS_YIELD_DETAILED_SUMMARY,
  MRP_REPORTS_DAILY_CONSUMPTION: MODULES_NAME.MRP_REPORTS_DAILY_CONSUMPTION,
  MRP_PRODUCT: MODULES_NAME.MRP_PRODUCTS,
  MRP_HOLIDAY_LIST: MODULES_NAME.MRP_HOLIDAY_LIST,
  WORKSTATIONS: MODULES_NAME.WORKSTATIONS,
  WORK_ORDERS: MODULES_NAME.WORK_ORDERS,
  MRP_PRODUCTION_PLAN: MODULES_NAME.PRODUCTION_PLAN,
  JOB_CARDS: MODULES_NAME.JOB_CARDS,
  MRP_OPERATOR_LIST: MODULES_NAME.MRP_OPERATOR_LIST,
  BOM_EXPLODE: MODULES_NAME.BOM_EXPLODE,
  STOCK_TRANSFER: MODULES_NAME.STOCK_TRANSFER,
  STOCK_ISSUE: MODULES_NAME.STOCK_ISSUE,
  STOCK_REQUEST: MODULES_NAME.STOCK_REQUEST,
  BOM_EXPLOSION: MODULES_NAME.BOM_EXPLOSION,
  BOM_EXPLOSION_SUB_GRID: MODULES_NAME.BOM_EXPLOSION_SUB_GRID,
  WORK_OUT: REMOTE_CONFIG_TABLES.WORK_OUT,
  FORECAST: MODULES_NAME.FORECAST,
  MASTER_FORECAST: MODULES_NAME.MASTER_FORECAST,
  SALES_BY_CLASS: MODULES_NAME.SALES_BY_CLASS,
  JOB_BRIEF: MODULES_NAME.JOB_BRIEF,
  RGASSET: MODULES_NAME.RGASSET,
  MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST:
    MODULES_NAME.MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST,
  AMORTIZATION: MODULES_NAME.AMORTIZATION,
  DEFERRED_EXPENSE: MODULES_NAME.DEFERRED_EXPENSE,
  VAT_RETURN: MODULES_NAME.VAT_RETURN,
  AMORTIZATION_TEMPLATES: MODULES_NAME.AMORTIZATION_TEMPLATES,
  AMORTIZATION_SCHEDULES: MODULES_NAME.AMORTIZATION_SCHEDULE,
  AMORTIZATION_JE: MODULES_NAME.AMORTIZATION_JE,
  LINK_JOURNAL_ENTRY: MODULES_NAME.LINK_JOURNAL_ENTRY,
  PRINT_TEMPLATE: MODULES_NAME.PRINT_TEMPLATE,
  LABEL_TEMPLATE: MODULES_NAME.LABEL_TEMPLATE,
  SKU_ORDER_TRACKING: MODULES_NAME.SKU_ORDER_TRACKING,
  VALUATION_BY_WAREHOUSE: MODULES_NAME.VALUATION_BY_WAREHOUSE,
  VALUATION_BY_WAREHOUSE_DETAILS: MODULES_NAME.VALUATION_BY_WAREHOUSE_DETAILS,
  GOODS_RECEIPT_MASTER: REMOTE_CONFIG_TABLES.BOOKS_GOODS_RECEIPT_MASTER,
  GOODS_RECEIPT_APPROVAL_LIST:
    REMOTE_CONFIG_TABLES.BOOKS_GOODS_RECEIPT_APPROVAL_LIST,
  FULFILLMENT_MASTER: REMOTE_CONFIG_TABLES.BOOKS_FULFILLMENT_MASTER,
  FULFILLMENT_APPROVAL_LIST: REMOTE_CONFIG_TABLES.GOODS_RECEIPT_APPROVAL_LIST,
  GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT:
    MODULES_NAME.GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT,
  FULFILLMENT_TOLERANCE_LEVEL_REPORT:
    MODULES_NAME.FULFILLMENT_TOLERANCE_LEVEL_REPORT,
  DELIVERY_ORDER_TOLERANCE_LEVEL_REPORT:
    MODULES_NAME.DELIVERY_ORDER_TOLERANCE_LEVEL_REPORT,
  TOLERANCE_LEVEL_REJECTION_STOCK_REPORT:
    MODULES_NAME.TOLERANCE_LEVEL_REJECTION_STOCK_REPORT,
  SO_TO_WO_REPORT: MODULES_NAME.SO_TO_WO_CONVERSION_REPORT,
  ORDER_LEVELS_REPORT: MODULES_NAME.ORDER_LEVELS_REPORT,
  GENERATE_BANK_FILE_INVOICE: MODULES_NAME.GENERATE_BANK_FILE_INVOICE,
  GENERATE_BANK_FILE_BILL: MODULES_NAME.GENERATE_BANK_FILE_BILL,
  PAYMENT_LOGS: MODULES_NAME.PAYMENT_LOGS,
  UPLOAD_TO_FTP_LOGS: MODULES_NAME.UPLOAD_TO_FTP_LOGS,
  SECURITY_GATE_ENTRY: MODULES_NAME.SECURITY_GATE_ENTRY,
  SALES_PERSON_MASTER: MODULES_NAME.SALES_PERSON_MASTER,
  BANK_BOOK_REPORT: MODULES_NAME.BANK_BOOK_REPORT,
  CONSOLIDATE_BANK_BOOK_REPORT: MODULES_NAME.CONSOLIDATE_BANK_BOOK_REPORT,
  WHATSAPP_API_CONNECTIONS: MODULES_NAME.WHATSAPP_API_CONNECTIONS,
  RFQ_CONTACT_MASTER: MODULES_NAME.RFQ_CONTACT_MASTER,
  PRODUCTS_BY_VENDOR: MODULES_NAME.PRODUCTS_BY_VENDOR
};
export const COLUMN_CODE = {
  INVOICE: {
    RowType: 'rowType',
    Number: 'documentSequenceCode',
    Code: 'salesInvoiceCode',
    CustomerOrderNo: 'customerOrderNumber',
    InvoiceDate: 'salesInvoiceDate',
    InvoiceDueDate: 'salesInvoiceDueDate',
    TotalAmount: 'totalAmount',
    PendingStatus: 'paymentStatus',
    FulfillmentStatus: 'fulfillmentStatus',
    Contact: 'contact',
    DueAmount: 'dueAmount',
    Id: 'id',
    ApprovalStatus: 'approvalStatus',
    Remarks: 'remarks',
    InvoiceNow: 'invoiceNow',
    ReservedStock: 'reservedStock',
    Recurring: 'recurring',
    EwayBillStatus: 'ewayBillStatus',
    COGS: 'cogsAmount',
    Profit: 'profit',
    GrossMargin: 'grossMargin',
    EInvoiceResponseStatus: 'einvoiceResponseStatus',
    EInvoiceResponseReason: 'einvoiceResponseReason',
    PaymentTerm: 'paymentTerm',
    CreatedBy: 'createdBy',
    PaymentFileGenerated: 'paymentFileGenerated'
  },
  CONTACT: {
    TenantID: 'tenantId',
    Number: 'documentSequenceCode',
    ContactName: 'name',
    Type: 'type',
    TheyOweYou: 'theyOweYou',
    YouOweThem: 'youOweThem',
    Status: 'status',
    Id: 'id'
  },
  PRODUCT: {
    Number: 'documentSequenceCode',
    Description: 'description',
    ProductName: 'name',
    AvailableStock: 'outgoingQty',
    Status: 'status',
    Category: 'type',
    Id: 'id',
    IMAGES: `images`,
    Reorder: 'reorder',
    Outgoing: 'outgoing',
    TransactionType: 'productTransactionType',
    PurchasePrice: 'purchasePrice',
    Barcode: 'barcode',
    ProductGroupIds: 'productGroupIds',
    RestockLevel: 'restocklevel'
  },
  PURCHASE_ORDER: {
    RowType: 'rowType',
    Code: 'poCode',
    Number: 'documentSequenceCode',
    DocumentDate: 'documentDate',
    TotalAmount: 'totalAmount',
    ReceiptStatus: 'receiptStatus',
    Currency: 'currencyCode',
    Contact: 'contact',
    DueDate: 'dueDate',
    OrderType: 'orderType',
    Id: 'id',
    Remarks: 'remarks',
    ApprovalStatus: 'approvalStatus',
    Recurring: 'recurring',
    PaymentTerm: 'paymentTerm',
    CreatedBy: 'createdBy'
  },
  FIXED_ASSET: {
    id: 'id',
    number: 'purchaseRequestForQuotesCode',
    openingAsset: 'openingAsset',
    installationDate: 'warrantyStartDate',
    name: 'name',
    documentCode: 'documentCode',
    documentSequenceCode: 'documentSequenceCode',
    items: 'item',
    purchaseDate: 'purchaseDate',
    assetGroup: 'assetGroup',
    tax: 'tax',
    discount: 'discount',
    amount: 'amount',
    bookValue: 'bookValue',
    netBookValue: 'netBookValue',
    profitLossAccountCode: 'profitLossAccountCode',
    salesIncomeAccountCode: 'salesIncomeAccountCode',
    warehouse: 'warehouse'
  },
  DEPRECIATION_SCHEDULE: {
    asset: 'asset',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    january: 'january',
    february: 'february',
    march: 'march',
    totalAmount: 'totalAmount'
  },
  JOB_WORK_OUT: {
    RowType: 'rowType',
    Code: 'poCode',
    Number: 'documentSequenceCode',
    DocumentDate: 'documentDate',
    TotalAmount: 'totalAmount',
    ReceiptStatus: 'receiptStatus',
    Currency: 'currencyCode',
    Contact: 'contact',
    DueDate: 'dueDate',
    OrderType: 'orderType',
    DispatchStatus: 'dispatchStatus',
    BilledStatus: 'billedStatus',
    Id: 'id'
  },
  REQUISITION: {
    RowType: 'rowType',
    Code: 'poCode',
    number: 'documentSequenceCode',
    DocumentDate: 'documentDate',
    TotalAmount: 'totalAmount',
    ReceiptStatus: 'receiptStatus',
    Currency: 'currencyCode',
    DueDate: 'dueDate',
    OrderType: 'orderType',
    Id: 'id',
    ApprovalStatus: 'approvalStatus',
    Recurring: 'recurring',
    req_date: 'purchaseRequestDate',
    due_date: 'shipByDate',
    total_quantity: 'total_quantity',
    attachments: 'attachments',
    memo: 'memo',
    requested_by: 'requested_by',
    receipt_status: 'receipt_status',
    linked_po: 'LinkedPO',
    approval_status_filter: 'approval_status_filter',
    product: 'productCode'
  },
  BILL: {
    RowType: 'rowType',
    Code: 'purchaseInvoiceCode',
    Number: 'documentSequenceCode',
    DocumentDate: 'purchaseInvoiceDate',
    TotalAmount: 'totalAmount',
    PendingStatus: 'paymentStatus',
    ReceiptStatus: 'receiveGoodsStatus',
    PurchaseInvoiceType: 'purchaseInvoiceType',
    Currency: 'currency',
    Contact: 'contact',
    DueAmount: 'dueAmount',
    PurchaseInvoiceDate: 'purchaseInvoiceDate',
    PurchaseInvoiceDueDate: 'purchaseInvoiceDueDate',
    EInvoiceResponseStatus: 'einvoiceResponseStatus',
    Id: 'id',
    Remarks: 'remarks',
    ApprovalStatus: 'approvalStatus',
    Recurring: 'recurring',
    EInvoiceResponseReason: 'einvoiceResponseReason',
    PaymentTerm: 'paymentTerm',
    CreatedBy: 'createdBy'
  },
  QUOTE: {
    RowType: 'rowType',
    Code: 'quotationCode',
    CustomerOrderNo: 'customerOrderNumber',
    Number: 'documentSequenceCode',
    DocumentDate: 'documentDate',
    DueDate: 'validTillDate',
    TotalAmount: 'totalAmount',
    FulfillmentStatus: 'fulfillmentStatus',
    Currency: 'currency',
    Contact: 'contact',
    Status: 'status',
    FulfillmentType: 'fulfillmentType',
    Id: 'id',
    ApprovalStatus: 'approvalStatus',
    Remarks: 'remarks',
    ReservedStock: 'reservedStock',
    Recurring: 'recurring',
    CreatedBy: 'createdBy',
    Crm3DealName: 'crm3DealName',
  },
  SALES_ORDER: {
    RowType: 'rowType',
    Code: 'salesOrderCode',
    Number: 'documentSequenceCode',
    SaleOrderDate: 'salesOrderDate',
    CustomerOrderNo: 'customerOrderNumber',
    SalesOrderDueDate: 'salesOrderDueDate',
    ShipByDate: 'shipByDate',
    TotalAmount: 'totalAmount',
    FulfillmentStatus: 'fulfillmentStatus',
    Currency: 'currency',
    Contact: 'contact',
    Status: 'status',
    FulfillmentType: 'fulfillmentType',
    Id: 'id',
    Remarks: 'remarks',
    ApprovalStatus: 'approvalStatus',
    ReservedStock: 'reservedStock',
    Recurring: 'recurring',
    AmountPaid: 'amountPaid',
    BalanceDue: 'balanceDue',
    ProductionStatus: 'productionStatus',
    ProductCode: 'productCode',
    OrderProgress: 'orderProgress',
    COGS: 'cogsAmount',
    Profit: 'profit',
    GrossMargin: 'grossMargin',
    PaymentTerm: 'paymentTerm',
    OrderReference: 'orderReference',
    BillTo: 'billTo',
    ShipTo: 'shipTo',
    CreatedBy: 'createdBy',
    LinkedWorkOrder: 'linkedWorkOrder',
    Attachments: 'attachments',
    AdvancedTrackingStatus: 'advancedTrackingStatus',
    StockReserved: 'stockReserved'
  },
  DRAFTS: {
    Name: 'name',
    Type: 'type',
    Payload: 'payload',
    AppName: 'app_name',
    isMaximized: 'is_maximized',
    isCenterAlign: 'is_center_align',
    isSaved: 'is_saved',
    Id: 'id'
  },
  JOURNAL_ENTRY: {
    Journal_Entry_Number: 'documentSequenceCode',
    Entry_Date: 'date',
    Entry_Type: 'type',
    Related_Document_Number: 'rdn',
    id: 'id'
  },
  COA: {
    Number: 'documentSequenceCode',
    Name: 'name',
    Type: 'type',
    ReportingBalance: 'balance',
    Status: 'status',
    Id: 'id',
    IsDefault: 'isDefault',
    Balance: 'balanceInAccountCurrency',
    Code: 'code',
    SubType: 'accountSubGroupName'
  },
  TAX_TABLE: {
    Name: 'name',
    Type: 'type',
    Description: 'description',
    Percent: 'percent',
    TaxCode: 'taxCode',
    AccountName: 'accountName',
    Visibility: 'visibility',
    Id: 'id'
  },
  PRICE_LIST: {
    PRICE_LIST_NAME: 'name',
    Type: 'type',
    EFFECTIVE_DATE: 'Date',
    CURRENCY: 'currency',
    STATUS: 'status',
    ID: 'id'
  },
  DEPOSITS: {
    Number: 'code',
    Contact: 'contactName',
    JournalNo: 'jeCode',
    DepositTo: 'accountName',
    PaymentDate: 'documentDate',
    TaxAmount: 'tax',
    AmountPaid: 'amount',
    Id: 'id'
  },
  EXPENSE: {
    Number: 'code',
    Contact: 'contactName',
    JournalNo: 'jeCode',
    PayFrom: 'accountName',
    PaymentDate: 'documentDate',
    TaxAmount: 'tax',
    AmountPaid: 'amount',
    Id: 'id',
    DocType: 'documentType'
  },
  FINANCIAL_YEAR_CLOSING: {
    PeriodName: 'name',
    JENumber: 'jeNumber',
    FromDate: 'fromDate',
    ToDate: 'toDate',
    ClosingAmount: 'closingAmount'
  },
  CREDIT_NOTES: {
    DocumentNo: 'code',
    JournalNo: 'jeCode',
    CreditNoteDate: 'cnDate',
    Contact: 'contact',
    Amount: 'amount',
    AmountDue: 'amountDue',
    CreditNoteType: 'type',
    Id: 'id',
    LinkedDocuments: 'linkedDocuments',
    InvoiceNow: 'invoiceNow',
    PartyJeCreditNote: 'partyJeCreditNote',
    DocumentCode: 'documentCode'
  },
  DEBIT_NOTES: {
    DocumentNo: 'code',
    JournalNo: 'jeCode',
    DebitNoteDate: 'dnDate',
    Contact: 'contact',
    Amount: 'amount',
    AmountDue: 'amountDue',
    DebitNoteType: 'type',
    Id: 'id',
    LinkedDocuments: 'linkedDocuments',
    InvoiceNow: 'invoiceNow',
    PartyJeDebitNote: 'partyJeDebitNote',
    DocumentCode: 'documentCode'
  },
  ACCOUNTS: {
    Code: 'code',
    AccountCode: 'accountCode',
    Account: 'name',
    AccountGroup: 'accountGroup',
    Currency: 'currency',
    ExchangeRate: 'exchangeRate',
    Debit: 'debit',
    credit: 'credit'
  },
  INVENTORY: {
    ID: 'id',
    PRODUCTID: 'productId',
    PRODUCTNAME: 'name',
    PRODUCTTYPE: 'type',
    INVENTORYACCOUNT: 'inventoryAccount',
    OPENINGVALUATION: 'openingValuation',
    OPENINGQUANTITY: 'openingQuantity'
  },
  WAREHOUSE: {
    ID: 'id',
    NAME: 'name',
    STATUS: 'status',
    IS_PRIMARY: 'isPrimary',
    CODE: 'code',
    IS_DELETE_ENABLED: 'isDeleteEnable',
    LOCATION_ID: 'location.id',
    LOCATION_NAME: 'location.name',
    LOCATION_ADDR_ADDR1: 'location.address.address1',
    LOCATION_ADDR_ADDR2: 'location.address.address1',
    LOCATION_ADDR_CITY: 'location.address.city',
    LOCATION_ADDR_STATE: 'location.address.state',
    LOCATION_ADDR_COUNTRY: 'location.address.country',
    LOCATION_ADDR_POSTALCODE: 'location.address.postalCode',
    LOCATION_ADDR_PREFERRED: 'location.address.preferred',
    ADDRESS: 'address'
  },
  OB_INVOICE: {
    DocumentNumber: 'documentSequenceCode',
    DocumentDate: 'invoiceDate',
    DocumentType: 'type',
    Duedate: 'invoiceDueDate',
    TotalAmount: 'totalAmount',
    AmountDue: 'dueAmount'
  },
  Bank: {
    Number: 'accountCode',
    Account: 'name',
    BanksAccount: 'bankName',
    ReportingBalance: 'reportingBalance',
    Balance: 'balance',
    status: 'status',
    Id: 'id'
  },
  EWAY_BILL: {
    SHIP_BY_DATE: 'shipByDate',
    TRANSACTION: 'salesInvoiceCode',
    CONTACT: 'customerName',
    CONTACT_GSTIN: 'gstin',
    TOTAL_AMOUNT: 'totalAmount'
  },
  PAYMENT_TERMS: {
    TermName: 'termName',
    TermDays: 'termDays',
    Id: 'id',
    Default: 'default'
  },
  BankRules: {
    Name: 'ruleName',
    Priority: 'priority',
    AppliedTo: 'appliedTo',
    Conditions: 'condition',
    Settings: 'settings',
    Status: 'status',
    AutoCreate: 'autoCreateTransaction',
    Id: 'id'
  },
  AUDIT_LOG: {
    Event: 'event',
    Date: 'date',
    User: 'user',
    Role: 'role',
    IpAddress: 'ipAddress',
    Details: 'details',
    Time: 'time'
  },
  CURRENCY: {
    Currency: 'currency',
    Code: 'code',
    Symbol: 'symbol',
    ExchangeRate: 'exchangeRate',
    ExchangeRateDate: 'exchangeRateDate',
    Visibility: 'visibility',
    Id: 'id'
  },
  UOM: {
    Name: 'name',
    Description: 'description',
    Id: 'id'
  },
  UOM_SCHEMA: {
    Name: 'schemaName',
    baseUom: 'defaultUom',
    status: 'status',
    Id: 'id'
  },
  IMPORT_LOG: {
    Module: 'module',
    Records: 'records',
    FileName: 'fileName',
    FileType: 'fileType',
    ImportedBy: 'importedBy',
    ImportedOn: 'importedOn'
  },
  IMPORT_DRAFT: {
    Name: 'name',
    Module: 'module',
    Date: 'createdAt',
    Status: 'status'
  },
  BANK_TRANSACTION: {
    Date: 'transactionDt',
    Payee: 'merchantName',
    Description: 'description',
    Withdrawn: 'withdrawn',
    Deposit: 'deposit',
    TransactionAmount: 'transactionAmount',
    Status: 'status',
    ImportedDt: 'importedDt',
    FromDt: 'fromDt',
    ToDt: 'toDt',
    ImportedFilePath: 'importedFilePath',
    Id: 'id',
    TransactionId: 'transactionId',
    ChequeNumber: 'chequeNumber',
    CurrencyCode: 'currencyCode',
    MatchingDocuments: 'matchingDocuments',
    CD_TYPE: 'cdType',
    UserMatchedDocumentCode: 'userMatchedDocumentCode',
    UserMatchedDocumentType: 'userMatchedDocumentType',
    StatementReconcliedStatus: 'statementReconcliedStatus'
  },
  DESKERA_TRANSACTION: {
    Number: 'documentCode',
    DocumentType: 'documentType',
    PaymentType: 'paymentType',
    ContactName: 'contactName',
    ReferenceNumber: 'referenceNumber',
    ReferenceDate: 'referenceDate',
    TransactionDate: 'transactionDate',
    Withdrawn: 'withdrawn',
    Deposit: 'deposit',
    Status: 'status',
    Action: 'action',
    IsCreateByRule: 'isCreateByRule',
    TransactionType: 'transactionType',
    Id: 'id'
  },
  TDS_RATES: {
    RowType: 'rowType',
    ApplicationDate: 'applicableFromDate',
    DeducteeType: 'deducteeType',
    Deleted: 'deleted',
    NatureOfPayment: 'natureOfPayment',
    Resident: 'resident',
    IsResident: 'isResident',
    TdsPayableAccount: 'tdsAccountPayable',
    TdsPayableAccountCode: 'tdsAccountPayableCode',
    TdsRates: 'tdsRate',
    TdsThreshold: 'thresholdLimit',
    Id: 'id'
  },
  TDS_NATURE_OF_INCOME: {
    RowType: 'rowType',
    SectionCode: 'sectionCode',
    NatureOfPayment: 'natureOfPayment',
    Id: 'id'
  },
  TDS_DEDUCTEE_TYPE: {
    RowType: 'rowType',
    DeducteeType: 'type',
    DeducteeCode: 'code',
    Id: 'id'
  },
  TCS_RATES: {
    RowType: 'rowType',
    NatureOfCollection: 'tcsCollectionNatureId',
    TaxName: 'taxName',
    PayableAccount: 'accountPayable',
    ReceivableAccount: 'accountReceivable',
    PercentageRate: 'ratePercentage',
    TcsReceivableAccountCode: 'tcsAccountReceivable',
    TcsPayableAccountCode: 'tcsAccountPayableCode',
    Id: 'id',
    status: 'status'
  },
  DEBIT_REPORT: {
    TotalAmount: 'total',
    DueAmount: 'due',
    AppliedAgainst: 'applied',
    DocumentDate: 'doc_date',
    Contact: 'contact',
    AmountInBase: 'base',
    Memo: 'memo',
    Currency: 'currency',
    DocumentCode: 'document',
    ExchangeRate: 'exchange'
  },
  GSTR1: {
    Description: 'desc',
    IGST: 'igst',
    CGST: 'cgst',
    SGST: 'sgst',
    Cess: 'cess',
    TaxTotal: 'tax',
    TotalTaxableAmount: 'total_taxable'
  },
  GSTR1Details: {
    Date: 'date',
    CustomerName: 'cname',
    InvoiceNumber: 'invoice',
    GSTIN: 'gstin',
    PlaceOfSupply: 'pos',
    TaxableAmount: 'taxable_amount',
    IntegratedTaxAmount: 'integrated',
    CentralTaxAmount: 'central',
    StateTaxAmount: 'state',
    CessAmount: 'cess',
    TotalTaxAmount: 'total_tax',
    TotalAmountInclTax: 'incl_tax',
    Type: 'type',
    TaxRate: 'rate'
  },
  GSTR1ExempDetails: {
    Date: 'date',
    CustomerName: 'customerName',
    InvoiceNumber: 'invoiceNumber',
    Gstin: 'gstin',
    PlaceOfSupply: 'placeOfSupply',
    NilAmount: 'nilAmount',
    ExemptAmount: 'exemptAmount',
    NonGstAmount: 'nonGstAmount'
  },
  GST_RETURN: {
    Name: 'name',
    ReturnType: 'return',
    Period: 'period',
    Status: 'status'
  },
  GSTR_THREE_ONE_A: {
    DocumentType: 'doc_type',
    GSTIN: 'gstin',
    Date: 'date',
    PersonName: 'pname',
    PlaceOfSupply: 'pos',
    TransactionAmount: 'trans',
    TaxableAmount: 'taxable',
    IntegratedTaxAmount: 'integrated',
    CentralTaxAmount: 'central',
    StateTaxAmount: 'state',
    CessAmount: 'cess',
    TotalTaxAmount: 'total_tax',
    TotalAmountInclTax: 'incl_tax'
  },
  TDS_DETAILS: {
    ContactName: 'cname',
    DocumentNumber: 'doc_no',
    DocumentDate: 'doc_date',
    DocumentType: 'doc_type',
    EmailIdOfContact: 'email',
    ContactAddress: 'address',
    CountryOfContact: 'country',
    PANNumber: 'pan',
    TANNumber: 'tan',
    NatureOfIncomePayment: 'nip',
    DeducteeType: 'deductee',
    TDSRate: 'tds_rate',
    TDSAssessableValue: 'tdsvalue',
    TDSAmount: 'amount',
    DueDate: 'due',
    OverDueByMonths: 'overdue'
  },
  TDS_NOT_DEDUCTED: {
    ContactName: 'cname',
    DocumentNumber: 'doc_no',
    NatureOfIncomePayment: 'nip',
    DeducteeType: 'deductee',
    ContactPANTANNumber: 'pan_tan',
    TDSRate: 'tds_rate',
    BillAmount: 'bill',
    TDSAmount: 'tds_amount',
    PendingTDS: 'pending'
  },
  STOCK_AGEING: {
    ProductName: 'prod_name',
    Quantity0: 'quantity_0',
    Value0: 'value_0',
    Quantity30: 'quantity_30',
    Value30: 'value_30',
    Quantity60: 'quantity_60',
    Value60: 'value_60',
    Quantity90: 'quantity_90',
    Value90: 'value_90',
    Quantity120: 'quantity_120',
    Value120: 'value_120'
  },
  VALUATION_BY_WAREHOUSE: {
    warehouseName: 'warehouseName',
    totalValuation: 'totalValuation'
  },
  VALUATION_BY_WAREHOUSE_DETAILS: {
    productSeqCode: 'productSeqCode',
    productName: 'productName',
    productDescription: 'productDescription',
    qtyAvailable: 'qtyAvailable',
    uom: 'uom',
    totalValue: 'totalValue',
    avgCost: 'avgCost'
  },
  BATCH_STATUS: {
    BatchNumber: 'bnumber',
    Supplier: 'supplier',
    ReceivedOn: 'recon',
    ExpiryDate: 'expiry',
    Product: 'product',
    Status: 'status',
    QtyRemaining: 'qtyrem',
    Remarks: 'remarks'
  },
  TDS_CUSTOMER: {
    CustomerName: 'customer_name',
    Tan: 'tan',
    TdsDeducted: 'tds'
  },
  HSN: {
    HSNCode: 'hsn',
    InvoiceNumber: 'invoice',
    Description: 'desc',
    UQC: 'uqc',
    TotalQuantity: 'total_q',
    TotalValue: 'total_v',
    IntegratedTaxAmount: 'integrated',
    CentralTaxAmount: 'central',
    StateTaxAmount: 'state',
    CessAmount: 'cess',
    TotalTaxAmount: 'total_tax'
  },
  CDNR: {
    Date: 'date',
    NoteNumber: 'note',
    InvoiceDate: 'invoice',
    GSTIN: 'gstin',
    SupplyType: 'supply',
    TaxableAmount: 'taxable_amount',
    CNAmount: 'cn',
    TaxRate: 'tax'
  },
  CDNUR: {
    Type: 'type',
    Date: 'date',
    NoteNumber: 'note',
    TotalAmountInclTax: 'incl',
    TaxRate: 'tax',
    TaxableAmount: 'taxable_amount',
    CessAmount: 'cess'
  },
  EXEMPT: {
    SupplyType: 'supply',
    NilAmount: 'nil',
    Exempt: 'exempt',
    NonGSTAmount: 'nongst'
  },
  BILL_LIST: {
    BillDate: 'bill_date',
    BillNumber: 'bill_number',
    Contact: 'contact',
    DueDate: 'due_date',
    status: 'status',
    Currency: 'currency',
    TotalAmount: 'total_amount',
    AmountDue: 'due',
    TaxAmount: 'tax',
    ExchangeRate: 'exchange',
    AmountInBase: 'base_amount',
    RelatedDocument: 'related_doc',
    ValidTIll: 'valid'
  },
  RECONCILIATION_LOG_REPORT: {
    ACCOUNT_NAME: 'accountName',
    ATTACHMENT: 'attachment',
    ATTACHMENT_ID: 'attachmentId',
    BANK_BOOK_BALANCE: 'bankBookBalance',
    BANK_STATEMENT_BALANCE: 'bankStatementBalance',
    CLEARANCE_DATE: 'clearanceDate',
    CURRENCY: 'currency',
    DIFFRENCE: 'difference',
    UNCLEARED_BALANCE: 'unclearedBalance',
    UNCLEARED_DEPOSITE_AMOUNT: 'unclearedDepositAmount',
    UNCLEARED_PAYMENT_AMOUNT: 'unclearedPaymentAmount',
    USERNAME: 'username'
  },
  GST_REPORT_LOG: {
    ID: 'id',
    NAME: 'name',
    REPORT_TYPE: 'reportType',
    TEMP_GST_RETURN_LOG_REPORT_ID: 'tempGSTReturnLogReportId',
    PERIOD: 'period',
    FROM_DATE: 'fromDate',
    TO_DATE: 'toDate',
    BALANCE: 'balance',
    STATUS: 'status',
    CREATION_DATE: 'creationDate',
    FILING_YEAR: 'filingYear',
    USERNAME: 'username',
    FREQUENCY: 'frequency',
    REPORT_SETTINGS: 'reportSettings',
    REPORT_DATA: 'reportData'
  },
  SALES_BY_PRODUCT: {
    ProductID: 'prod_id',
    ProductName: 'prod_name',
    Quantity: 'quantity',
    Amount: 'amount',
    Cogs: 'cogs',
    SalesPercent: 'sales_per',
    AvgSalesPrice: 'sales_price',
    GrossPercent: 'gross_per',
    GrossMargin: 'gross_margin',
    Total: 'total'
  },
  ORDER_LEVEL_REPORT: {
    PRODUCT_ID: 'productId',
    PRODUCT_NAME: 'productName',
    PRODUCT_CODE: 'productCode',
    OUTSTANDING_SO: 'openSoSiCount',
    OUTSTANDING_WO: 'openWoCount',
    TOTAL_AVAILABLE_QTY: 'totalAvailableQty',
    RESTOCKING_LEVEL: 'reStockingLevel',
    NET_AVAILABLE_QTY: 'netAvailableQty',
    INCOMING_QTY: 'incomingQty'
  },
  PURCHASE_BY_PRODUCT: {
    ProductID: 'prod_id',
    ProductName: 'prod_name',
    Quantity: 'quantity',
    Amount: 'amount',
    PurchasePercent: 'purchase_per',
    AveragePurchasePrice: 'purchase_price'
  },
  SALES_BY_CONTACT: {
    Contact: 'contact',
    GrossSales: 'gross_sales',
    Discount: 'discount',
    NetSales: 'net_sales',
    ContactCode: 'contactCode',
    DocumentDate: 'documentDate',
    DocumentNumber: 'documentNumber',
    Product: 'product',
    Memo: 'memo',
    Quantity: 'qty',
    SalesPrice: 'salesPrice'
  },
  PURCHASE_BY_CONTACT: {
    Contact: 'contact',
    GrossSales: 'gross_purchase',
    Discount: 'discount',
    NetSales: 'net_purchase',
    ContactCode: 'contactCode',
    DocumentDate: 'documentDate',
    DocumentNumber: 'documentNumber',
    Product: 'product',
    Memo: 'memo',
    Quantity: 'qty',
    SalesPrice: 'purchasePrice'
  },
  STOCK_MOVEMENT: {
    LinkedDocument: 'linked',
    QtyOut: 'out',
    ContactName: 'contact',
    StockTransactionDate: 'transaction',
    QtyIn: 'in',
    Warehouse: 'warehouse',
    BatchSerial: 'bs',
    DocumentDate: 'doc_date',
    ProductCode: 'prod',
    DocumentCode: 'doc_code',
    ProductName: 'prod_name',
    ContactCode: 'con_code'
  },
  INVOICE_REPORT: {
    TaxAmount: 'tax',
    TotalAmount: 'total',
    CustomerOrderNo: 'customer_order_no',
    RelatedDocuments: 'related',
    Contact: 'contact',
    DueAmount: 'due',
    ExchangeRate: 'exchange',
    DueDate: 'due_date',
    DocumentDate: 'doc_date',
    AmountInBase: 'amount_base',
    Currency: 'currency',
    InvoiceNumber: 'invoice',
    Status: 'status'
  },
  RECEIPT_LIST: {
    DocumentDate: 'doc_date',
    ReceiptNumber: 'doc_number',
    RelatedDocuments: 'related',
    Contact: 'contact',
    ReceivedAgainst: 'rec_ag',
    Currency: 'currency',
    TotalAmount: 'total_amount',
    ExchangeRate: 'exchange',
    AmountInBase: 'base',
    DepositTo: 'deposit',
    ReceiptInfo: 'receipt',
    Memo: 'memo'
  },
  OUTSTANDING_SALES_FULLFILLMENT_REPORT: {
    TaxAmount: 'tax_amount',
    TotalAmount: 'amount',
    Contact: 'contact_name',
    ExchangeRate: 'exchange_rate',
    QuoteDate: 'quote_date',
    AmountInBase: 'base_amount',
    Currency: 'currency',
    QuoteNumber: 'quote_number',
    Status: 'status',
    AfterTaxAmount: 'amount_after_tax'
  },
  VARIANT: {
    SERIAL: 'serial',
    Name: 'variantName',
    Number: 'variantNumber',
    Barcode: 'variantBarcode',
    AvailableQty: 'availableQty',
    ReorderLevel: 'reorderLevel',
    ReorderQty: 'reorderQty',
    Status: 'status',
    Id: 'id',
    ProductId: 'productId'
  },
  BATCH_TRACKED: {
    BatchNumber: 'batchNumber',
    ManufacturedDate: 'manufacturedDate',
    ExpiryDate: 'expiryDate',
    QtyInBatch: 'qtyInBatch',
    AVAILABLE_QTY_IN_BATCH: 'availableQtyInBatch',
    AcquiredCost: 'acquiredCost',
    Id: 'id'
  },
  CUSTOM_REPORT: {
    Name: 'reportName',
    CreatedBy: 'createdBy',
    DateRange: 'dateRange',
    AppName: 'appName',
    Id: 'id'
  },
  AUTOMATION: {
    NAME: `name`,
    CREATED_ON: `created_on`,
    SCHEDULED_ON: `scheduled_on`,
    LAST_RUN_DATE: `last_run_date`,
    JSON_DATA: `json_data`,
    STATUS: `status`,
    CONNECTION_ID: `workflow_connection_id`,
    CREATED_AT: `created_at`
  },
  PRINT_CHECK: {
    CODE: `code`,
    DATE: `documentDate`,
    TYPE: `type`,
    CONTACT_NAME: `contactName`,
    STATUS: `status`,
    AMOUNT: `amount`,
    CHECK_NO: `referenceNumber`
  },
  BOOKKEEPER: {
    CLIENT: {
      ORGANISATION_NAME: 'organizationName',
      FULLNAME: 'fullName',
      BOOKKEEPERPAYS: 'bookkeeperPays',
      EMAIL: 'email',
      CONTACT: 'contact',
      STATUS: 'status'
    },
    TEAM: {
      FULLNAME: 'fullName',
      EMAIL: 'email',
      CONTACT: 'contact',
      STATUS: 'status'
    }
  },
  AGENCY_FORM: {
    Level: 'level',
    GrossSales: 'grossSales',
    NonTaxableSales: 'nonTaxableSales',
    TaxableSales: 'taxableSales',
    TaxAmount: 'taxAmount',
    Id: 'id'
  },
  BATCH_SERIAL_LEDGER_REPORT: {
    STOCK_TRANSACTION_DATE: 'stock_in_out_dt',
    DOCUMENT_TYPE: 'documentType',
    DOCUMENT_NUMBER: 'documentNo',
    DOCUMENT_DATE: 'documentDate',
    CONTACT_NAME: 'contactName',
    QTY_IN: 'qty_in',
    QTY_OUT: 'qty_out',
    UOM: 'uom',
    VALUE: 'value',
    QTY_ON_HAND: 'qty_on_hand',
    VALUE_ON_HAND: 'value_on_hand',
    PRODUCT_CODE: 'productCode',
    PRODUCT_NAME: 'productName',
    TRANSACTION_ORDER: 'transactionOrder',
    WAREHOUSE: 'warehouse',
    BATCH_SERIALS: 'batchserials',
    TOTAL: 'total'
  },
  GSTR2B: {
    INVOICE2B: 'invoice2B',
    INVOICEPB: 'invoicePB'
  },
  STOCK_ADJUSTMENT: {
    NUMBER: 'code',
    DATE: 'formattedAdjustmentDate',
    WAREHOUSE: 'warehouseName',
    ADJUSTMENT_REASON: 'adjustmentReason',
    REASON: 'reason',
    STOCK_TYPE: 'adjustmentType',
    NOTES: 'notes',
    LINK_DOCUMENT: 'linkedDocuments'
  },
  STOCK_TRANSFER: {
    transferNumber: 'code',
    date: 'transferDate',
    notes: 'notes'
  },
  STOCK_REQUEST: {
    id: 'id',
    documentSequenceCode: 'documentSequenceCode',
    approveStatus: 'approveStatus',
    transferNumber: 'code',
    date: 'stockRequestDate',
    notes: 'notes',
    stockRequestCode: 'stockRequestCode',
    requestQuantity: 'request_quantity',
    //requestWarehouse: 'warehouseName',
    status: 'status',
    issueStatus: 'issueStatus',
    linkedDocuments: 'linkedDocuments'
  },
  STOCK_ISSUE: {
    documentSequenceCode: 'documentSequenceCode',
    stockIssueCode: 'stockIssueCode',
    id: 'id',
    Number: 'Number',
    stockIssueDate: 'stockIssueDate',
    Date: 'Date',
    Notes: 'Notes',
    Status: 'Status'
  },

  ASSET_GROUP: {
    name: 'name',
    depreciationMethod: 'depreciationMethod',
    assetAccount: 'assetAccount',
    accumulatedDepreciation: 'accumulatedDepreciation',
    depreciationExpense: 'depreciationExpense',
    active: 'active'
  },
  AUTOMATION_LOGS: {
    AUTOMATION: 'automationName',
    TRIGGER_TYPE: 'triggerNodeType',
    ACTION_TYPE: 'executedNodeType',
    LOG: 'log',
    EXECUTED_AT: 'executedAt'
  },
  BOOKS_REPORTS_SALES_ORDER_DETAILS: {
    customerName: 'customerName',
    customerCode: 'customerCode',
    orderDate: 'orderDate',
    expectedDateOfFulfillment: 'expectedDateOfFulfillment',
    dueDate: 'dueDate',
    itemName: 'itemName',
    itemCode: 'itemCode',
    qtyOrdered: 'qtyOrdered',
    picked: 'picked',
    packed: 'packed',
    shippedFulfilled: 'shippedFulfilled',
    backOrdered: 'backOrdered',
    available: 'available',
    basePrice: 'basePrice',
    unitPrice: 'unitPrice',
    subTotal: 'subTotal',
    taxAmount: 'taxAmount',
    discount: 'discount',
    total: 'total',
    linkedQuote: 'linkedQuote',
    linkedInvoice: 'linkedInvoice',
    linkedPayment: 'linkedPayment',
    linkedFulfillment: 'linkedFulfillment'
  },
  BOOKS_GOODS_RECEIPT_MASTER: {
    SEQUENCE_NO_GR: 'sequenceNoGR',
    GRN_JE_NUMBER: 'jeNumberGR',
    VENDOR_NAME: 'vendorName',
    VENDOR_CODE: 'vendorCode',
    GRN_DATE: 'grnDate',
    TOTAL_AMOUNT_GR: 'totalAmountGR',
    LINKED_DOC: 'linkedPurchaseDoc',
    SUPPLIER_INVOICE_NO: 'supplierInvoiceNo',
    QC_STATUS: 'qcStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    MEMO: 'memo',
    ATTACHMENTS: 'attachments',
    LINKED_DRAFT_GR: 'linkedDraftGR',
    IS_QC_ENABLE: 'isQcEnabled',
    CONTACT_NAME: 'contactName'
  },
  BOOKS_GOODS_RECEIPT_APPROVAL_LIST: {
    SEQUENCE_NO_GR: 'sequenceNoGR',
    VENDOR_NAME: 'vendorName',
    VENDOR_CODE: 'vendorCode',
    GRN_DATE: 'grnDate',
    TOTAL_AMOUNT_GR: 'totalAmountGR',
    LINKED_DOC: 'linkedPurchaseDoc',
    SUPPLIER_INVOICE_NO: 'supplierInvoiceNo',
    QC_STATUS: 'qcStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    MEMO: 'memo',
    ATTACHMENTS: 'attachments',
    APPROVERS: 'approvers',
    IS_QC_ENABLE: 'isQcEnabled',
    CONTACT_NAME: 'contactName'
  },
  BOOKS_FULFILLMENT_MASTER: {
    SEQUENCE_NO_FF: 'sequenceNoFF',
    JE_NUMER_FF: 'jeNumberFF',
    CUSTOMER_NAME: 'customerName',
    CUSTOMER_CODE: 'customerCode',
    FULFILLMENT_DATE: 'fulfillmentDate',
    TOTAL_AMOUNT_FF: 'totalAmountFF',
    LINKED_DOC: 'linkedSalesDoc',
    CUSTOMER_ORDER_NO: 'customerOrderNo',
    QC_STATUS: 'qcStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    MEMO: 'memo',
    ATTACHMENTS: 'attachments',
    LINKED_DRAFT_FF: 'linkedDraftFF',
    IS_QC_ENABLE: 'isQcEnabled',
    CONTACT_NAME: 'contactName'
  },
  BOOKS_FULFILLMENT_APPROVAL_LIST: {
    SEQUENCE_NO_FF: 'sequenceNoFF',
    CUSTOMER_NAME: 'customerName',
    CUSTOMER_CODE: 'customerCode',
    FULFILLMENT_DATE: 'fulfillmentDate',
    TOTAL_AMOUNT_FF: 'totalAmountFF',
    LINKED_DOC: 'linkedSalesDoc',
    CUSTOMER_ORDER_NO: 'customerOrderNo',
    QC_STATUS: 'qcStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    MEMO: 'memo',
    ATTACHMENTS: 'attachments',
    APPROVERS: 'approvers',
    IS_QC_ENABLE: 'isQcEnabled',
    CONTACT_NAME: 'contactName'
  },
  PAYMENT_LOG: {
    NUMBER: 'logCode',
    DATE_OF_GENERATION: 'createdAt',
    PAYMENT_FILE: 'fileName',
    GENERATION_TYPE: 'type',
    DOCUMENT_TYPE: 'documentType',
    LINKED_RECORDS: 'linkedDocuments',
    NO_OF_DOCUMENTS: 'noOfLinkedDocuments'
  },
  UPLOAD_TO_FTP_LOG: {
    NUMBER: 'logCode',
    DATE_OF_GENERATION: 'createdAt',
    PAYMENT_FILE: 'fileName',
    GENERATION_TYPE: 'type',
    DOCUMENT_TYPE: 'documentType',
    LINKED_RECORDS: 'linkedDocuments',
    NO_OF_DOCUMENTS: 'noOfLinkedDocuments'
  },
  BANK_BOOK_REPORT: {
    TRANSACTION_AMOUNT: 'amount',
    TRANSACTION_CURRENCY: 'currency',
    DEBIT_AMOUNT: 'debitAmount',
    CREDIT_AMOUNT: 'creditAmount',
    BALANCE: 'balanceAmount',
    BALANCE_AMOUNT_IN_DOCUMENT_CURRENCY: 'balanceAmountInDocumentCurrency',
    JE_DATE: 'jeDate',
    EXCHANGE_RATE: 'exchangeRate',
    DOCUMENT_TYPE: 'documentType',
    DOCUMENT_CURRENCY: 'documentCurrency'
  },
  PREFERRED_VENDOR: {
    SUPPLIER_CODE: 'supplierCode',
    LEAD_TIME: 'leadTime',
    SUPPLIER_PART_NAME: 'supplierPartName',
    SUPPLIER_DESCRIPTION: 'supplierDescription',
    SUPPLIER_PART_NO: 'supplierPartNumber',
    LAST_PRICE: 'lastPrice',
    AVG_PRICE: 'avgPrice',
    SUPPLIER_PRICE: 'supplierPrice',
    NOTES: 'notes',
    ACTION: 'action'
  },
  PRODUCT_BY_VENDOR: {
    PRODUCT_NAME: 'productName',
    PRODUCT_CODE: 'productCode',
    SUPPLIER_PART_NO: 'supplierPartNumber',
    SUPPLIER_PART_NAME: 'supplierPartName',
    SUPPLIER_DESCRIPTION: 'supplierDescription',
    LAST_PRICE: 'lastPrice',
    AVG_PRICE: 'avgPrice',
    SUPPLIER_PRICE: 'supplierPrice',
    LEAD_TIME: 'leadTime'
  }
};

export const TABLE_DISPLAY_NAME = {
  [TABLES.CONTACT]: 'Contacts',
  [TABLES.INVOICE]: 'Invoices',
  [TABLES.PRODUCT]: 'Products',
  [TABLES.BILL]: 'Bills',
  [TABLES.QUOTATION]: 'Quotes',
  [TABLES.SALES_ORDERS]: 'Sales Orders',
  [TABLES.PURCHASE_ORDER]: 'Purchase Orders',
  [TABLES.REQUISITION]: 'Requisitions',
  [TABLES.FIXED_ASSET]: 'Fixed Asset',
  [TABLES.ASSET_GROUP]: 'Asset Group',
  [TABLES.DEPRECIATION_SCHEDULE]: 'Depreciation Schedule',
  [TABLES.DRAFTS]: 'Drafts',
  [TABLES.SETTINGS]: 'Settings',
  [TABLES.JOURNAL_ENTRY]: 'Journal Entry',
  [TABLES.DEBIT_NOTES]: 'Debit Notes',
  [TABLES.CREDIT_NOTES]: 'Credit Notes',
  [TABLES.COA]: 'Chart Of Accounts',
  [TABLES.ACCOUNTING]: 'Accounting',
  [TABLES.SETTINGS]: 'Settings',
  [TABLES.ACCOUNTS]: 'Accounts',
  [TABLES.WAREHOUSE]: 'Warehouses',
  [TABLES.IMPORT]: 'Import',
  [TABLES.BANK]: 'Banks',
  [TABLES.EWAY_BILL]: 'E-way Bill',
  [TABLES.CLASSES]: 'Classes',
  [TABLES.TAX]: 'Tax',
  [TABLES.PRICE_LIST]: 'Price List',
  [TABLES.PRICE_BOOK]: 'Price Book',
  [TABLES.BANK]: 'Banks',
  [TABLES.REVENUE_ARRANGEMENT]: 'Revenue Arrangement',
  [TABLES.EXPENSE]: 'Expense',
  [TABLES.DEPOSIT]: 'Deposit',
  [TABLES.AUDIT_LOG]: 'Audit Logs',
  [TABLES.CURRENCY]: 'Multi Currency',
  [TABLES.IMPORT_LOG]: 'Import Logs',
  [TABLES.UOM]: 'Unit of Measurement',
  [TABLES.UOM_SCHEMA]: 'Unit of Measurement Schema',
  [TABLES.FY_CLOSING]: 'Financial Year Closing Log ',
  [TABLES.REPORTS]: 'Reports',
  [TABLES.DEBIT_REPORT]: 'Debit Notes',
  [TABLES.CREDIT_REPORT]: 'Credit Notes',
  [TABLES.INVOICE_REPORT]: 'Invoice',
  [TABLES.RECEIPT_LIST]: 'Receipt List',
  [TABLES.PAYMENT_LIST]: 'Payment List',
  [TABLES.AGED_RECEIVABLES_REPORT]: 'Aged Receivables',
  [TABLES.MILESTONE_REPORT]: 'Payment Milestone',
  [TABLES.AGED_PAYABLES_REPORT]: 'Aged Payables',
  [TABLES.AUTOMATIONS]: 'Automations',
  [TABLES.APPS]: 'Apps',
  [TABLES.PRINT_CHECKS]: 'Print Checks',
  [TABLES.AGENCIES]: 'Agencies',
  [TABLES.CONSOLIDATION_FINANCIAL_REPORT]: 'Consolidated Financial Reports',
  [TABLES.CONSOLIDATION_FINANCIAL_REPORT_LIST]:
    'Consolidated Financial Reports List',
  [TABLES.WORK_OUT]: 'Work Out',
  [TABLES.MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST]:
    'Multi Company Consolidated Financial Reports',
  [TABLES.AMORTIZATION_TEMPLATES]: 'Amortization Templates',
  [TABLES.AMORTIZATION_SCHEDULES]: 'Amortization Schedules',
  [TABLES.AMORTIZATION_JE]: 'Create Amortization JE',
  [TABLES.PRINT_TEMPLATE]: 'Print Template',
  [TABLES.LABEL_TEMPLATE]: 'Label Template',
  [TABLES.GOODS_RECEIPT_MASTER]: 'Goods Receipts',
  [TABLES.GOODS_RECEIPT_APPROVAL_LIST]: 'Pending Approval',
  [TABLES.FULFILLMENT_MASTER]: 'Fulfillments',
  [TABLES.FULFILLMENT_APPROVAL_LIST]: 'Pending Approval',
  [TABLES.PAYMENT_LOGS]: 'Payment Logs',
  [TABLES.UPLOAD_TO_FTP_LOGS]: 'Upload to FTP Logs',
  [TABLES.GENERATE_BANK_FILE_INVOICE]: 'Invoices',
  [TABLES.GENERATE_BANK_FILE_BILL]: 'Bills',
  [TABLES.SECURITY_GATE_ENTRY]: 'Security Gate Entry',
  [TABLES.BANK_BOOK_REPORT]: 'Bank Book Report',
  [TABLES.CONSOLIDATE_BANK_BOOK_REPORT]: 'Consolidated Bank Book Report',
  [TABLES.PRODUCTS_BY_VENDOR]: 'Products'
};

export const TABLES_WITH_FREEZABLE_COLUMNS = [
  REMOTE_CONFIG_TABLES.PRODUCT,
  REMOTE_CONFIG_TABLES.CONTACT,
  REMOTE_CONFIG_TABLES.INVOICE,
  REMOTE_CONFIG_TABLES.QUOTATION,
  REMOTE_CONFIG_TABLES.PURCHASE_ORDER,
  REMOTE_CONFIG_TABLES.SALES_ORDER,
  REMOTE_CONFIG_TABLES.BILL
];
