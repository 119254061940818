import ApiConstants from '../Constants/ApiConstants';
import { COUNTRY_CODES, TReportResponse } from '../Constants/Constant';
import { AccountingPayload } from '../Models/AccountingForm';
import { BankDepositPayload, UndepositItem } from '../Models/Deposit';
import { ApiConfig } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import AuthService from './Auth';
import http from './Interceptor';

export interface DepositAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  DocType?: any;
  Query?: any;
  QueryParam?: any;
  Sort?: any;
  SortDir?: any;
  SalesPerson?: number;
}

const defaultConfig: DepositAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  DocType: '',
  Query: '',
  QueryParam: '',
  SalesPerson: 0
};
class DepositService {
  static apiConfig: DepositAPIConfig = defaultConfig;
  static abortController: any = null;

  static getDepositByPage() {
    Utility.cancelRequest(DepositService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&limit=${this.apiConfig.Limit ? this.apiConfig.Limit : ''}&page=${
      this.apiConfig.Page !== undefined &&
      this.apiConfig.Page !== null &&
      this.apiConfig.Page >= 0
        ? this.apiConfig.Page
        : ''
    }${this.apiConfig.DocType ? `&docType=${this.apiConfig.DocType}` : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }
    if (this.apiConfig.SalesPerson) {
      queryString = queryString + '&salesperson=' + this.apiConfig.SalesPerson;
    }

    const finalEndpoint: string =
      ApiConstants.URL.DEPOSIT.FETCH_DEPOSITS + queryString;

    DepositService.abortController = Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: DepositService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getDepositByCodde(code: any) {
    let url = `${ApiConstants.URL.BASE}payments/receive/deposits?query=&search=${code}&limit=25&page=0`;
    return http
      .get(url)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static updateDeposit(payload: any) {
    return http
      .post(`${ApiConstants.URL.DEPOSIT.UPDATE_DEPOSIT}`, payload)
      .catch((err: any) => {
        console.error('Error updating deposit: ', err);
        return Promise.reject(err);
      });
  }

  static createDirectDeposit(payload: AccountingPayload) {
    return http
      .post(`${ApiConstants.URL.DEPOSIT.UPDATE_DEPOSIT}`, payload)
      .catch((err: any) => {
        console.error('Error creating deposit: ', err);
        return Promise.reject(err);
      });
  }

  static deleteDeposit(depositCode: string) {
    return http
      .delete(`${ApiConstants.URL.DEPOSIT.NEW_DELETE_DEPOSIT(depositCode)}`)
      .catch((err: any) => {
        console.error('Error deleting expense: ', err);
        return Promise.reject(err);
      });
  }
  
  static deleteBankDeposit(depositCode: string) {
    return http
      .delete(`${ApiConstants.URL.DEPOSIT.DELETE_BANK_DEPOSIT(depositCode)}`)
      .catch((err: any) => {
        console.error('Error deleting expense: ', err);
        return Promise.reject(err);
      });
  }

  static deleteReceivePayment(paymentCode: string, documentCode: string) {
    let countryCode = '';
    return http
      .delete(
        `${ApiConstants.URL.PAYMENT.NEW_DELETE_INVOICE_PAYMENT_RECORD(
          paymentCode,
          documentCode,
          countryCode
        )}`
      )
      .catch((err: any) => {
        console.error('Error deleting receive payment: ', err);
        return Promise.reject(err);
      });
  }

  static getBankAccountList(assetList: string[]) {
    return http
      .post(`${ApiConstants.URL.DEPOSIT.FETCH_BANK_ACCOUNT_LIST}`, assetList)
      .catch((err: any) => {
        console.error('Error fetching bank account list: ', err);
        return Promise.reject(err);
      });
  }

  static async fetchUndeposits(paymentCode: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    let finalEndpoint: string =
      ApiConstants.URL.DEPOSIT.FETCH_UNDEPOSITS_PAYMENTS + queryString;
    if (!Utility.isEmpty(paymentCode)) {
      queryString += '&paymentCode=' + paymentCode;
      finalEndpoint =
        ApiConstants.URL.DEPOSIT.FETCH_LINKED_UNDEPOSITS_PAYMENTS + queryString;
    }
    return http
      .get(`${finalEndpoint}`)
      .then((response: any) => {
        return Promise.resolve<TReportResponse<UndepositItem>>(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static createBankDeposit(payload: BankDepositPayload) {
    return http
      .post(`${ApiConstants.URL.DEPOSIT.CREATE_BANK_DEPOSIT}`, payload)
      .catch((err: any) => {
        console.error('Error creating Bank Deposit: ', err);
        return Promise.reject(err);
      });
  }

  static updateBankDeposit(payload: BankDepositPayload) {
    return http
      .put(`${ApiConstants.URL.DEPOSIT.CREATE_BANK_DEPOSIT}`, payload)
      .catch((err: any) => {
        console.error('Error updating Bank Deposit: ', err);
        return Promise.reject(err);
      });
  }

  static exportDeposit(query: string) {
    const finalEndpoint: string =
      ApiConstants.URL.DEPOSIT.EXPORT_DEPOSIT + (query ? query : '');
    return http
      .get(`${finalEndpoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static saveBankDepositImportMapping(payload: any) {
    return http
      .post(`${ApiConstants.URL.DEPOSIT.SAVE_IMPORT_MAPPINGS}`, payload)
      .catch((err: any) => {
        console.error('Error saving import mappings: ', err);
        return Promise.reject(err);
      });
  }

  static fetchBankDepositImportMapping(payload: any) {
    return http
      .post(`${ApiConstants.URL.DEPOSIT.FETCH_IMPORT_MAPPINGS}`, payload)
      .catch((err: any) => {
        console.error('Error fetching import mappings: ', err);
        return Promise.reject(err);
      });
  }
}

export default DepositService;
