import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility, { getDateFrom, getDateString } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class PickingListTableItem {
    invoiceOrQuotation: string;
    code: string;
    name: string;
    barcode: string;
    quantityToPick: string;
    quantityForOrder: string;
    picked: string;
    warehouseName: string;
    warehouseCode: string;
    serialBatch: string;
    serialNumber: string;
    batchNumber: string;
    batchQuantity: string;
    manufacturingDate: string;
    expiryDate: string;
    selectedRow: string;
    selectedRack: string;
    selectedBin: string;

    rawItemValue: any;
    data: any;
    constructor(pickingListItem: any, data:any, currency: string) {
        this.invoiceOrQuotation = pickingListItem.invoiceOrQuotation
        this.code = pickingListItem.code ? pickingListItem.code : ''
        this.name = this.getProductName(data, pickingListItem)
        this.barcode = pickingListItem.barcode
        this.quantityToPick = pickingListItem.quantityToPick ? pickingListItem.quantityToPick.toString(): '0'
        this.quantityForOrder = pickingListItem.quantityForOrder ? pickingListItem.quantityForOrder.toString() : '0'
        this.picked = pickingListItem.picked ? getLocalisedText('yes').toUpperCase() : getLocalisedText('no').toUpperCase()
        this.warehouseName = pickingListItem.warehouseName ? pickingListItem.warehouseName : ''
        this.warehouseCode = pickingListItem.warehouseCode ? pickingListItem.warehouseCode : ''
        this.serialBatch = pickingListItem.serialBatch
        this.serialNumber = pickingListItem.serialNumber ? pickingListItem.serialNumber : ''
        this.batchNumber = pickingListItem.batchNumber ? pickingListItem.batchNumber : ''
        this.batchQuantity = pickingListItem.batchQuantity ? pickingListItem.batchQuantity : ''
        this.manufacturingDate = pickingListItem.manufacturingDate ? pickingListItem.manufacturingDate : ''
        this.expiryDate = pickingListItem.expiryDate ? pickingListItem.expiryDate : ''
        this.selectedRow = pickingListItem?.rowName ?? ''
        this.selectedRack = pickingListItem?.rackName ?? ''
        this.selectedBin = pickingListItem?.binName ?? ''
        
        this.rawItemValue = pickingListItem
        this.data = data
    }
    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }

    getBatchQty(lineItem: any): string {
        return isNaN(parseFloat(lineItem.batchQuantity)) ? '' : Utility.toDecimalFormat(parseFloat(lineItem.batchQuantity), TemplateSettingsManager.getQuantityDecimalScale())
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + field.value + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }
                                            
                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + field.value + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }
        return val
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.invoiceOrQuotation:
                return getLocalisedText(this.invoiceOrQuotation)
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.barcode:
                return this.barcode
            case TableColumnPopupType.quantityToPick:
                return this.quantityToPick ? this.quantityToPick : ''
            case TableColumnPopupType.quantityRequiredForOrder:
                return this.quantityForOrder ? this.quantityForOrder : ''
            case TableColumnPopupType.picked:
                return this.picked ? this.picked : ''
            case TableColumnPopupType.warehouseName:
                return this.warehouseName
            case TableColumnPopupType.warehouseCode:
                return this.warehouseCode
            case TableColumnPopupType.serialBatch:
                return this.serialBatch ? this.serialBatch : ''
            case TableColumnPopupType.serialNumber:
                return this.serialNumber ? this.serialNumber : ''
            case TableColumnPopupType.batchNumber:
                return this.batchNumber ? this.batchNumber : ''
            case TableColumnPopupType.batchQuantity:
                return this.batchQuantity ? this.batchQuantity : ''
            case TableColumnPopupType.manufacturingDate:
                return this.manufacturingDate ? this.manufacturingDate : ''
            case TableColumnPopupType.expiryDate:
                return this.expiryDate ? this.expiryDate : ''
            case TableColumnPopupType.selectedRow:
                return this.selectedRow ?? ''
            case TableColumnPopupType.selectedRack:
                return this.selectedRack ?? ''
            case TableColumnPopupType.selectedBin:
                return this.selectedBin ?? ''
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        switch (cfType) {
                            case "DATE":
                                value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                                break;
                            case "NUMBER":
                                if (element.showAsCurrency && !isNaN(parseFloat(field.value))) {
                                    value = Utility.toCurrencyFormat(parseFloat(field.value) * 1, '')
                                } else {
                                    value = field.value
                                }
                                break;
                            default:
                                value = field.value
                                break;
                        }
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            case TableColumnPopupType.serialBatchCustomFields:
                return CustomFieldManager.getCombineSerialBatchCustomField(this.rawItemValue, this.data);
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.invoiceOrQuotation),
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            this.barcode,
            this.quantityToPick,
            this.quantityForOrder,
            this.picked,
        ]
    }
}
